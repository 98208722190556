import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useToken } from "../auth/useToken";
import { useUser } from "../auth/useUser";
import { Link } from "react-router-dom";
import { UserInfo } from "./UserInfo";
import fencers01 from "../images/icons/AS_200858950.jpg";
import { ReceiptsList} from '../util/ReceiptsList';

import "./css/MainPage.css";

export const MainPage = (props) => {
    const location = useLocation();
    console.log(location.state); //https://ui.dev/react-router-v5-pass-props-to-link/
        console.log("@@ MainPage - PROPS : "+JSON.stringify(props));
    const user = useUser();
    const [token, setToken] = useToken();

    const { id, email, isVerified, info } = user;


    // We'll use the history to navigate the user
    // programmatically later on (we're not using it yet)
    const history = useHistory();

    // And here we have the JSX for our component. It's pretty straightforward
    return (
        <div className='container lh-sm fw-light px-5 mt-4'>
            {!isVerified && <div className="text-danger bg-warning p-2 m-1">You won't be able to make any changes until you verify your email</div>}
            <div className='row'>
                <div className='col-9'>
                    <div className="card bg-dark border-light my-1">
                        <div className="card-body text-left"></div>
                    </div>
                    <div className="card border-primary my-1" style={{"backgroundColor":"#D5D5D5"}}>
                        <div className="card-body text-left">
                                <Link to="/profile" >My Account</Link>
                        </div>
                        <div className="card-body text-left">
                                <Link to="#" title="Coming Soon" className="text-black">Rankings</Link>
                        </div>
                        <div className="card-body text-left">
                                <Link to="/paymentactivities" >Payments</Link>
                        </div>
                        <div className="card-body text-left">
                                <Link to="#" className="" title="Comint Soon" className="text-black">Member Search</Link>
                        </div>
                        <div className="card-body text-left">
                                <Link to="/events" >Events Calendar</Link>
                        </div>
                        <div className="card-body text-left">
                                <Link to="#" className="" title="Comint Soon" className="text-black">My Tournament History</Link>
                        </div>
                    </div>
                    <div className="card bg-dark border-light my-1">
                        <div className="card-body text-lef"></div>
                    </div>
                </div>
                <div className='col-3'>
                    <div className="row">
                        <div className="card bg-light gy-1 m-0 p-0">
                            <div className="card-header text-dark fs-6">Account:</div>
                            <div className="card-body text-dark m-0 p-1" style={{"fontSize":"0.7em"}}><UserInfo user={user} classAct={"text-dark"} />
                                <a href="/profile" className="card-link text-info">edit</a>
                            </div>
                            <div className="card-footer text-info">
                            </div>
                        </div>
                        <div className="card bg-light gy-1">
                            <img className="card-img p-1" src={fencers01} alt="fencers 01"/>
                        </div>
                        <div className="card bg-light gy-1 m-0 p-0">
                            <p className="card-header text-dark fs-6">Receipts</p>
                            <div className="card-body text-dark m-0 p-1" style={{"fontSize":"0.7em"}}>
                                <ReceiptsList classAct={"text-dark"} />
                            </div>
                        </div>
                        <div className="card bg-light gy-1">
                            <p className="p-1 text-info">TBA</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
//        <div className="content-container position-relative bg-light rounded-2 px-2 py-2"></div>
//                        <div className="card-body text-left">
//                                <Link to="/testtournament01" className="text-info">Tournament Payment</Link>
//                        </div>