export const xlateErrorMessage = (e) => {
    //console.log("xlateErrorMessage - e: "+JSON.stringify(e)); // will include password
    if (e.message !== "Network Error") {
    const code = e.response.status;

    var message = '';

    switch(code) {
        case 401:
            message = "Not Authorized. Check your credentials. Do you have an account with us?";
            break;
        case 409:
            message = "Conflict detected. Check your credentials. There may be duplicate data with us. Please report to support.";
            break;
        default:
            message = "Error due to the status code " + e.response.status + ". Please report to support.";
    }

    return message

    } else { // Network Error
        return "Network Error detected. Is it possible you're under Proxy? Proxy may not work."
    }
}