import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import browserInfo from '@smartbear/browser-info';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import { addPayment } from '../util/addPayment';
import axios from 'axios';
import purify from 'dompurify';
import { useUserData } from '../auth/useUserData';


const { nanoid } = require('nanoid');
let errorDisplay = null;

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
       baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
       baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

// https://developer.squareup.com/docs/web-payments/take-card-payment
// https://codesandbox.io/s/square-web-payments-react-nhgxy?file=/src/App.js

browserInfo.detect();

const APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
const LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID;
const isSafari = browserInfo.name === "Safari";
const paymentRequestMock = {
  countryCode: "US",
  currencyCode: "USD",
  lineItems: [
    { amount: "45.00", label: "tournament001", pending: false },
  ],
  requestBillingContact: true,
  requestShippingContact: false,
  shippingContact: {
    addressLines: ["1 Test St", ""],
    city: "San Francisco",
    countryCode: "US",
    email: "test@squareup.com",
    familyName: "First Name",
    givenName: "Last Name",
    phone: "+12345678910",
    postalCode: "11111",
    state: "CA"
  },
  shippingOptions: [
    { amount: "0.00", id: "FREE", label: "Free" },
    //{ amount: "9.99", id: "XP", label: "Express" }
  ],
  total: { amount: "45.00", label: "Total", pending: false }
};

// This function tokenizes a payment method.
// The ‘error’ thrown from this async function denotes a failed tokenization,
// which is due to buyer error (such as an expired card).
export async function tokenizePaymentMethod(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  // A list of token statuses can be found here:
  // https://developer.squareup.com/reference/sdks/web/payments/enums/TokenStatus
  if (tokenResult.status === "OK") {
    return tokenResult.token;
  }
  let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
  if (tokenResult.errors) {
    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
  }
  throw new Error(errorMessage);
}

///////////////////////////
///////////////////////////

export const TestTournament01 = () => {
  const history = useHistory();
  const user = useUser();
  const [token, setToken] = useToken();

  console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  console.log("@@ TestTournament01 - type of user: "+typeof(user));
  console.log("@@ TestTournament01 - user: "+JSON.stringify(user));
  const { id: userId, email, isVerified, info, tobi, } = user; // destructuring assignment
  console.log("@@ TestTournament01 - userId: "+JSON.stringify(userId));




  console.log(" @@ TestTournament01 - user: "+JSON.stringify(user));
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  console.log("TestTournament01 - user: "+JSON.stringify(user));

  const [loaded, setLoaded] = useState(false);
  const [squarePayments, setSquarePayments] = useState(undefined);
  const [squareCard, setSquareCard] = useState(undefined);
  //const [applePay, setApplePay] = useState(undefined);
  //const [googlePay, setGooglePay] = useState(undefined);
  const [isSubmitting, setSubmitting] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false); // for "Confirm Order" button
  const [orderConfirmMessageError, setOrderConfirmMessageError] = useState('');
  const [messageDisplay, setMessageDisplay] = useState('');
  const [validFields, setValidFields] = useState({
    cardNumber: false,
    cvv: false,
    expirationDate: false,
    postalCode: false
  });
  const isCardFieldsValid = Object.values(validFields).every((v) => v);


  const [selectedItems, setSelectedItems] = useState([
    { id: "Registration", currency: "USD", price: 40, quantity: 1},
  ]);

  //const [fullOrder, setFullOrder] = useState({});
  const [fullOrder, setFullOrder] = useState({
    total_items:1,
    total_charges: 40,
    currency: "USD",
    line_items: selectedItems
  });

  let Total = 0;
  let onChange = async (e) => {
    Total = 0;
    let order = {};
    let UpdatedSelectedItems = [];
    UpdatedSelectedItems = selectedItems;
    if (e.target.checked == true) {
      await UpdatedSelectedItems.push({
        id: e.target.id,
        //status: e.target.checked,
        currency: "USD",
        price: e.target.value,
        quantity: "1",
      });

      // setSelectedItems(UpdatedSelectedItems);
    } else {
      UpdatedSelectedItems = await UpdatedSelectedItems.filter(
        (item) => !(item.id == e.target.id)
      );
      // setSelectedItems(UpdatedSelectedItems);
    }

    await UpdatedSelectedItems.map((item, id) => {
      Total += parseInt(item.price);
    });

    order = {
      total_items: UpdatedSelectedItems.length,
      total_charges: Total,
      currency: "USD",
      line_items: UpdatedSelectedItems,
    };
    // console.log(order);
    // console.log(UpdatedStates);
    // console.log(Total);
    setSelectedItems(UpdatedSelectedItems);
    setFullOrder(order);
  };
  console.log("data", fullOrder);




  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [receiptMessage, setReceiptMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [receiptIssued, setReceiptIssued] = useState(false);
  const [msg, setMsg] = useState(null);


  const userData = useUserData(userId);
  console.log("TestTournament01 - userData: "+JSON.stringify(userData));

  // This useEffect hook automatically hides the
  // success and error messages after 3 seconds when they're shown.
  useEffect(() => {
      if (showSuccessMessage || showErrorMessage) {
          setTimeout(() => {
              setShowSuccessMessage(false);
              //setShowErrorMessage(false); // error messages should not go away....
              //history.go();
          }, 10000);
      }
  }, [showSuccessMessage, showErrorMessage]);

  // Add Square script to the page
  useEffect(() => {
    const existingScript = document.getElementById("webPayment");
    if (existingScript) setLoaded(true);
    else {
      const script = document.createElement("script");
      script.src = "https://sandbox.web.squarecdn.com/v1/square.js";
      script.id = "webPayment";
      document.body.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, []);


  // Instantiate Square payments and store the object in state
  useEffect(() => {
    if (loaded && !squarePayments) {
      if (!window?.Square) {
        console.error("Square.js failed to load properly");
        return;
      }
      setSquarePayments(window.Square?.payments(APPLICATION_ID, LOCATION_ID));
      console.log("TestTournament01: squarePayments: "+JSON.stringify(squarePayments));
    }
  }, [loaded, squarePayments]);


  const handleConfirmOrderClick = () => {
    if (fullOrder.total_charges > 0) {
      setOrderConfirmMessageError('');
      setOrderConfirmed(true);
      //setLoaded(true);// to populate card attachment
      console.log("#### handleConfirmOrderClick - loaded: ", loaded);
      console.log("#### handleConfirmOrderClick - squarePayments: ", squarePayments);
      //initializeSquareCard(); // to populate card attachment
    } else {
      setOrderConfirmMessageError('You have not selected any orderable items.');
    }
  }

  // Handle the form submission
  const handlePaymentMethodSubmission = async (paymentMethod) => {
    const isCard = paymentMethod?.element?.id === "card-container";
    if (isCard && !isCardFieldsValid) return;
    if (!isSubmitting) {
      // Disable the submit button as we await tokenization and make a
      // payment request
      if (isCard) setSubmitting(true);
      try {
        const token = await tokenizePaymentMethod(paymentMethod); // Create your own addPayment function to communicate with your API // await addPayment(token) console.log("@@ @@ TestTournament01 - derived TOKEN:", token); // this works kokanish // https://developer.squareup.com/docs/web-payments/take-card-payment
        //const paymentResults = await createPayment(token);
    //    const paymentResults = await createPayment(token);
        let payload = token; //kokanish

        const idempotencyKey = payload.idempotencyKey || nanoid();
        const requestBody = {
          idempotencyKey,
          //locationId: payload.locationId,
          locationId: LOCATION_ID,
          //sourceId: payload.sourceId,
          sourceId: token,
          note: "FLA Tournament 001", // this gets displayed in the "transactions" of the dash
                                      // and Description
          // referenceId cannot be more than 20 chars!!! - per error message returned
          // ex. 3rd party order ID, etc
          referenceId: "ref-"+info.memberId+"-tournament001",
          // statementDescriptionIdentifier cannot be more than 20 chars!!! - per error message returned
          statementDescriptionIdentifier: "FlaTournent001Desc",
          // While it's tempting to pass this data from the client
          // Doing so allows bad actor to modify these values
          // Instead, leverage Orders to create an order on the server
          // and pass the Order ID to createPayment rather than raw amounts
          // See Orders documentation: https://developer.squareup.com/docs/orders-api/what-it-does
          amountMoney: {
          // the expected amount is in cents, meaning this is $1.00.
          //amount: '100',
          ////////////amount: paymentRequestMock.total.amount,
          //amount: paymentRequestMock.total.amount,
          amount: fullOrder.total_charges,
          // If you are a non-US account, you must change the currency to match the country in which
          // you are accepting the payment.
          //currency: 'USD', // hard coding
          currency: fullOrder.currency,
          },
          buyerEmailAddress: email,
          customerId: info.memberId,
        };


        console.log(" @@@ TestTournament01 - requestBody for 'addPayment()': "+JSON.stringify(requestBody));
        // if (payload.customerId) {
        //   payment.customerId = payload.customerId;
        // }
        // // VerificationDetails is part of Secure Card Authentication.
        // // This part of the payload is highly recommended (and required for some countries)
        // // for 'unauthenticated' payment methods like Cards.
        // if (payload.verificationToken) {
        //   payment.verificationToken = payload.verificationToken;
        // }

        //////////////////////////////////
        // This is the "square.paymentsApi.createPayment" / addPayment() portion mentioned above
        //////////////////////////////////
        const response = await addPayment(requestBody);
        console.log (" @@ TestTournament01 - response back from addPayment(requestBody) json: "+JSON.stringify(response));
        console.log(" @@ TestTournament01 - deconstrunturing...");
        //const { success, message } = JSON.stringify(response);
        const { success, message } = response
        console.log (" @@ TestTournament01 - returned deconst success: "+success);
        console.log (" @@ TestTournament01 - returned deconst message json: "+JSON.stringify(message));

        //////////////////////////////////

        if (success) { // checking response from addPayment

          const {id, createdAt, amountMoneyAmount, amountMoneyCurrency,
            approvedMoneyAmount, approvedMoneyCurrency,
            sourceType, cardBrand, last4,
            locationId, orderId, referenceId, statementDescriptionIdentifier,
            receiptNumber, receiptUrl} = message;
          
          console.log(" @@ TestTournament01 - deconst const id: "+id);
          console.log(" @@ TestTournament01 - deconst const createdAt: "+createdAt);
          console.log(" @@ TestTournament01 - deconst const amountMoneyAmount: "+amountMoneyAmount);
          console.log(" @@ TestTournament01 - deconst const amountMoneyCurrency: "+amountMoneyCurrency);
          console.log(" @@ TestTournament01 - deconst const approvedMoneyAmount: "+approvedMoneyAmount);
          console.log(" @@ TestTournament01 - deconst const approvedMoneyCurrency: "+approvedMoneyCurrency);
          console.log(" @@ TestTournament01 - deconst const sourceType: "+sourceType);
          console.log(" @@ TestTournament01 - deconst const cardBrand: "+cardBrand);
          console.log(" @@ TestTournament01 - deconst const last4: "+last4);
          console.log(" @@ TestTournament01 - deconst const locationId: "+locationId);
          console.log(" @@ TestTournament01 - deconst const orderId: "+orderId);
          console.log(" @@ TestTournament01 - deconst const referenceId: "+referenceId);
          console.log(" @@ TestTournament01 - deconst const statementDescriptionIdentifier: "+statementDescriptionIdentifier);
          console.log(" @@ TestTournament01 - deconst const receiptNumber: "+receiptNumber);
          console.log(" @@ TestTournament01 - deconst const receiptUrl: "+receiptUrl);

          // how to convert back RFC 3339 UTC date format:
          // const myDT = Date.parse("2022-01-26T22:11:51.855Z");
          // var date = new Date(myDT); // --> "Wed Jan 26 2022 17:11:51 GMT-0500 (EST)"

          const myDT = createdAt;
          const dispDT = new Date(myDT);

          const msg = `<div>
            <h2>Receipt - ${dispDT}</h2>
            <ul>
            <li>Total Amount: ${approvedMoneyCurrency} ${(Math.round(approvedMoneyAmount)/100).toFixed(2)}</li>
            <li>${sourceType} ${cardBrand}: "${last4}"</li>
            <li>Order#: ${orderId}</li>
            <li>${statementDescriptionIdentifier}</li>
            <li>Receipt#: ${receiptNumber}</li>
            </ul>
            <p>Receipt Link: <a role="button" class="btn btn-outline-primary" href="${receiptUrl}" target="_blank">#${receiptNumber}</a></p>
          </div>`
          setReceiptMessage(msg);

          // process recepts to database!

          const msgToDB = {data: {
            userId: userId,
            id: id, // payment id
            createdAt: createdAt,
            amountMoneyAmount: amountMoneyAmount,
            amountMoneyCurrency: amountMoneyCurrency,
            approvedMoneyAmount: approvedMoneyAmount,
            approvedMoneyCurrency: approvedMoneyCurrency,
            sourceType: sourceType,
            cardBrand: cardBrand,
            last4: last4,
            locationId: locationId,
            orderId: orderId,
            referenceId: referenceId,
            statementDescriptionIdentifier: statementDescriptionIdentifier,
            receiptNumber: receiptNumber,
            receiptUrl: receiptUrl,
            fullOrder: fullOrder
          }};
          console.log(" @@ TestTournament01 - msgToDB: "+JSON.stringify(msgToDB));

          const dbInsResult = await axios.post(`https://${baseUrl}/api/insertreceipt`, msgToDB);

          console.log(" @@ TestTournament01 - dbInsResult: "+JSON.stringify(dbInsResult.data));

          if (dbInsResult.data !== "OK") {
            setErrorMessage('transaction successful; however, receipt recording failed. Please report to support.');
          }



          setIsSuccess(true);
          setIsLoading(false);
          setShowSuccessMessage(true);

          //console.log(" @@@@ TestTournament01 - payment:"+payment);
          console.log(" @@@@ TestTournament01 - receiptNumber:"+receiptNumber);

        
        } // end of "checking response from addPayment"
        else {
          //

          throw "Processing failed...."+message;
        }


      } catch (error) { // tokenize failed
        console.error("FAILURE error:", error);
        setMessageDisplay(error);
        setShowErrorMessage(true);
      } finally {
        isCard && setSubmitting(false);
      }
    }
  };

  const handleBackClick= () => {
    //history.goBack();
    history.push("/main");
  }

  // Set each card field validity on various events
  const handleCardEvents = ({ detail }) => {
    if (detail) {
      const { currentState: { isCompletelyValid } = {}, field } = detail;
      if (field) {
        setValidFields((prevState) => ({
          ...prevState,
          [field]: isCompletelyValid
        }));
      }
    }
  };
/*
  const initializeApplePay = async () => {
    const paymentRequest = squarePayments.paymentRequest(paymentRequestMock);
    const aPay = await squarePayments.applePay(paymentRequest);
    setApplePay(aPay);
    // Note: Apple pay does not need to be "attached"
  };*/

  /*
  const attachGooglePay = (gPay) => {
    const googlePayObject = gPay || googlePay;
    googlePayObject.attach("#google-pay", {
      buttonColor: "white",
      buttonSizeMode: "fill",
      buttonType: "long"
    });
  };
  */

  /*
  const initializeGooglePay = async () => {
    const paymentRequest = squarePayments.paymentRequest(paymentRequestMock);

    // We *MUST* return a PaymentRequestUpdate from shipping contact/option
    // event listeners below
    // https://developer.squareup.com/reference/sdks/web/payments/objects/PaymentRequestUpdate
    const paymentRequestUpdate = {
      // error: "There was an error of some kind",
      // shippingErrors: {
      //   addressLines: "Error with the Address Lines",
      //   city: "Error with the City",
      //   country: "Error with the Country",
      //   postalCode: "Error with the Postal Code",
      //   state: "Error with the state",
      // },
      lineItems: paymentRequestMock.lineItems,
      shippingOption: paymentRequestMock.shippingOptions,
      total: paymentRequestMock.total
    };

    // Listener for shipping address changes
    paymentRequest.addEventListener("shippingcontactchanged", (contact) => {
      console.log({ contact });

      return paymentRequestUpdate;
    });
    // Listener for shipping option changes
    paymentRequest.addEventListener("shippingoptionchanged", (option) => {
      console.log({ option });

      return paymentRequestUpdate;
    });

    const gPay = await squarePayments.googlePay(paymentRequest);
    setGooglePay(gPay);
    attachGooglePay(gPay);
  };
  */

  // Attach the Square card to our container and setup event listeners
  const attachCard = (card) => {
    console.log("####### attachCard()");
    console.log("####### attachCard() - cardObject",squareCard);
    // We pass in the card object during initialization, but re-use it from
    // state for normal re-renders
    const cardObject = card || squareCard;
    if (isVerified) cardObject.attach("#card-container"); // won't attach if not verified
    //if (isVerified && orderConfirmed) cardObject.attach("#card-container"); // won't attach if not verified
    // Listeners: https://developer.squareup.com/reference/sdks/web/payments/objects/Card#Card.addEventListener
    cardObject.addEventListener("submit", () =>
      handlePaymentMethodSubmission(cardObject)
    );
    cardObject.addEventListener("focusClassAdded", handleCardEvents);
    cardObject.addEventListener("focusClassRemoved", handleCardEvents);
    cardObject.addEventListener("errorClassAdded", handleCardEvents);
    cardObject.addEventListener("errorClassRemoved", handleCardEvents);
    cardObject.addEventListener("cardBrandChanged", handleCardEvents);
    cardObject.addEventListener("postalCodeChanged", handleCardEvents);
  };

  const initializeSquareCard = async () => {
    console.log("########## initializeSquareCard");
    const card = await squarePayments.card();
    console.log("########## initializeSquareCard", card);
    setSquareCard(card);
    attachCard(card);
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    let isMounted = true; // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    if (squarePayments) {
      if (!squareCard) initializeSquareCard();
      //if (!applePay && isSafari) initializeApplePay();
      //if (!googlePay) initializeGooglePay();
      //else attachGooglePay();
    }
    // Otherwise, we destroy the objects and reset state
    else {
      if (squareCard) {
        squareCard.destroy();
        setSquareCard(undefined);
      }
      //if (applePay) {
      //  applePay.destroy();
      //  setApplePay(undefined);
      //}
      //if (googlePay) {
      //  googlePay.destroy();
      //  setGooglePay(undefined);
      //}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { isMounted = false};
  }, [squarePayments]);

  // Some quick button styles
  let cardButtonStyles = {
    backgroundColor: "#ddd",
    color: "white",
    padding: 16,
    fontFamily: "sans-serif",
    fontSize: "1rem",
    marginBottom: 16,
    borderRadius: 8,
    borderWidth: 0
  };
  if (isCardFieldsValid) {
    cardButtonStyles = {
      ...cardButtonStyles,
      backgroundColor: "green"
    };
  }

  return (
    <div className='container px-5' >
      <h1>Super Duper Tournament Payment Page</h1>
      <p>The tournament will be held on sometime this year. Please join us for the super duper moment.</p>
      <p>The fee is only $45.00 (USD) and will include both tournament registration and a parking space.</p>

    {console.log(" @@ TestTournament01 - userData.gender: "+JSON.stringify(userData.gender))}

    <div className='card text-dark mb-4 ' style={{"width": "400px"}}>
    <div className='card-header'>Event Registration Fee</div>
    <div className='card-body'>
       <div className='card-title'>Registration ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox"
             checked id="Registration" disabled />
          <label className='form-check-label' htmlFor='Registration'>Event Registration Fee</label>
       </div>
       <a href="/" className='card-link'>more info</a>
    </div>
    <div className='card-footer'>Mandatory Fee</div>
    </div>


    {(userData.gender==='Female') && 
    <>
    <div className='card text-dark mb-4' style={{"width": "400px"}}>
    <div className='card-header'>Events - Women's Épée</div>
    <div className='card-body'>
       <div className='card-title'>Junior ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             onChangeCapture={(e) => { onChange(e);}}
          value="40"
          id="U19WE"
          />
          <label className='form-check-label' htmlFor='U19WE'>Junior Women's Épée</label>
       </div>
       <p></p>
       <div className='card-title'>Cadet ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
          id="U16WE"
          value="40" onChangeCapture={(e) => { onChange(e);}}
          />
          <label className='form-check-label' htmlFor='U16WE'>Cadet Women's Épée</label>
       </div>
       <p></p>
       <div className='card-title'>Y-14 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y14WE"
             value="40" onChangeCapture={(e) => { onChange(e);}}
          />
          <label className='form-check-label' htmlFor='Y14WE'>Y-14 Women's Épée</label>
       </div>
       <p></p>
       <div className='card-title'>Y-12 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y12WE"
             value="40" onChangeCapture={(e) => { onChange(e);}}
          />
          <label className='form-check-label' htmlFor='Y12WE'>Y-12 Women's Épée</label>
       </div>
       <a href="/" className='card-link'>more info</a>
    </div>
    <div className='card-footer'>Now on sale</div>
    </div>
    <div className='card text-dark mb-4' style={{"width": "400px"}}>
    <div className='card-header'>Events - Women's Foil</div>
    <div className='card-body'>
       <div className='card-title'>Junior ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U19WF"
             value="40" onChangeCapture={(e) => { onChange(e);}}
          />
          <label className='form-check-label' htmlFor='U19WF'>Junior Women's Foil</label>
       </div>
       <p></p>
       <div className='card-title'>Cadet ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U16WF"
             value="40" onChangeCapture={(e) => { onChange(e);}}
          />
          <label className='form-check-label' htmlFor='U16WF'>Cadet Women's Foil</label>
       </div>
       <p></p>
       <div className='card-title'>Y-14 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y14WF"
             value="40" onChangeCapture={(e) => { onChange(e);}}
          />
          <label className='form-check-label' htmlFor='Y14WF'>Y-14 Women's Foil</label>
       </div>
       <p></p>
       <div className='card-title'>Y-12 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y12WF"
             value="40" onChangeCapture={(e) => { onChange(e);}}
          />
          <label className='form-check-label' htmlFor='Y12WF'>Y-12 Women's Foil</label>
       </div>
       <a href="/" className='card-link'>more info</a>
    </div>
    <div className='card-footer'>Now on sale</div>
    </div>
    <div className='card text-dark mb-4' style={{"width": "400px"}}>
    <div className='card-header'>Events - Women's Sabre</div>
    <div className='card-body'>
       <div className='card-title'>Junior ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U19WS"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U19WS'>Junior Women's Sabre</label>
       </div>
       <p></p>
       <div className='card-title'>Cadet ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U16WS" value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U16WS'>Cadet Women's Sabre</label>
       </div>
       <p></p>
       <div className='card-title'>Y-14 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y14WS" value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y14WS'>Y-14 Women's Sabre</label>
       </div>
       <p></p>
       <div className='card-title'>Y-12 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y12WS" value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y12WS'>Y-12 Women's Sabre</label>
       </div>
       <a href="/" className='card-link'>more info</a>
    </div>
    <div className='card-footer'>Now on sale</div>
    </div>
    </>
    }
    {(userData.gender==='Male') && 
    <>
    <div className='card text-dark mb-4' style={{"width": "400px"}}>
    <div className='card-header'>Events - Men's Épée</div>
    <div className='card-body'>
       <div className='card-title'>Junior ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U19ME"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U19ME'>Junior Men's Épée</label>
       </div>
       <p></p>
       <div className='card-title'>Cadet ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U16ME"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U16ME'>Cadet Men's Épée</label>
       </div>
       <p></p>
       <div className='card-title'>Y-14 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y14ME"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y14ME'>Y-14 Men's Épée</label>
       </div>
       <p></p>
       <div className='card-title'>Y-12 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y12ME"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y12ME'>Y-12 Men's Épée</label>
       </div>
       <a href="/" className='card-link'>more info</a>
    </div>
    <div className='card-footer'>Now on sale</div>
    </div>
    <div className='card text-dark mb-4' style={{"width": "400px"}}>
    <div className='card-header'>Events - Men's Foil</div>
    <div className='card-body'>
       <div className='card-title'>Junior ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U19MF"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U19MF'>Junior Men's Foil</label>
       </div>
       <p></p>
       <div className='card-title'>Cadet ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U16MF"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U16MF'>Cadet Men's Foil</label>
       </div>
       <p></p>
       <div className='card-title'>Y-14 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y14MF"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y14MF'>Y-14 Men's Foil</label>
       </div>
       <p></p>
       <div className='card-title'>Y-12 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y12MF"
             value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y12MF'>Y-12 Men's Foil</label>
       </div>
       <a href="/" className='card-link'>more info</a>
    </div>
    <div className='card-footer'>Now on sale</div>
    </div>
    <div className='card text-dark mb-4' style={{"width": "400px"}}>
    <div className='card-header'>Events - Men's Sabre</div>
    <div className='card-body'>
       <div className='card-title'>Junior ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U19MS" value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U19MS'>Junior Men's Sabre</label>
       </div>
       <p></p>
       <div className='card-title'>Cadet ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="U16MS" value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='U16MS'>Cadet Men's Sabre</label>
       </div>
       <p></p>
       <div className='card-title'>Y-14 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y14MS" value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y14MS'>Y-14 Men's Sabre</label>
       </div>
       <p></p>
       <div className='card-title'>Y-12 ($40)</div>
       <div className='form-check'>
          <input className='form-check-input' type="checkbox" disabled={orderConfirmed}
             id="Y12MS" value="40" onChangeCapture={(e) => { onChange(e);}} />
          <label className='form-check-label' htmlFor='Y12MS'>Y-12 Men's Sabre</label>
       </div>
       <a href="/" className='card-link'>more info</a>
    </div>
    <div className='card-footer'>Now on sale</div>
    </div>
    </>
    }

      {/* Apple Pay will not work with the demo Square IDs used in this file,
      but it will if you have a valid square account with Apple Pay configured
      correctly. If so, change the IDs to your sandbox and try it out */}
      {/*isSafari && !isSuccess && (
        <div
          id="apple-pay"
          onClick={() => handlePaymentMethodSubmission(applePay)}
          style={{
            backgroundColor: "white",
            padding: 11,
            borderColor: "#bbb",
            borderWidth: 1,
            boxShadow: "0px 2px 4px #00000033",
            fontFamily: "sans-serif",
            fontSize: "0.9rem",
            marginBottom: 16,
            borderRadius: 3
          }}
        >
          <span>Buy with Apple Pay</span>
        </div>
      )*/}
      {/*<div style={{ marginBottom: 24 }}>
        { !isSuccess && (
          <div
          id="google-pay"
          onClick={() => handlePaymentMethodSubmission(googlePay)}
        />
        )}
        </div>*/}
        
{console.log("fullOrder: ", fullOrder)}

<div className='card text-dark'>
  <div className='card-title px-2 pt-1'>Order Summary</div>

    {fullOrder.line_items && fullOrder.line_items.length > 0 &&
      <div className='card-body'>
        {fullOrder.line_items.map(item => {
              return <div className='row px-2 py-0 mx-2' key={fullOrder.line_items.id}>
                  <div className='col'>{item.id}</div><div className='col'>{item.currency} {item.price}</div>
                </div>
            }
          )
        }</div>}

  <div className='card-footer'>
    <div className='row'>
      <div className='col fw-bold'>Total:</div>
      <div className='col fw-bold'> {fullOrder.currency} {fullOrder.total_charges}</div>
      </div>
  </div>
</div>

{orderConfirmMessageError && !fullOrder.total_items && <div className='text-danger bg-warning px-2 mt-3 rounded-3'>{orderConfirmMessageError}</div>}
<div className='d-flex justify-content-center'>
  <button type='button' className='btn btn-success px-4 py-3 mt-5'
    onClick={() => handleConfirmOrderClick()}
    disabled={orderConfirmed}
  >{!orderConfirmed?"Confirm Order Above":(!isSuccess?"Submit Payment Below":"Thank You!")}</button>
</div>

{console.log("fullOrder['line_items']: ", fullOrder["line_items"])}
{console.log("selectedItems: ", selectedItems)}

      {!isVerified && <div className="text-danger pt-5">We cannot process any card payments until you verify your email.</div>}
      {isSuccess && (<><p>Success!!</p><hr/></>)}
      {isSuccess && errorMessage != null && (<p>{errorMessage}</p>)}
      {showSuccessMessage && <div className="success">Successfully processed payment!</div>}
      {showErrorMessage && <div className="error">Payment processing failed - {messageDisplay}</div>}
      {isSuccess && <div id="receipt" dangerouslySetInnerHTML={{__html: purify.sanitize(receiptMessage)}} />}



      {/* <form id="payment-form"> */}
        <div
          style={{
            display: (orderConfirmed?"flex":"none"),
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#144378",
            opacity: "0.8",
            padding: "15px 20px",
            margin: "20px",
          }}
        >
          {!isSuccess && (<div id="card-container"></div>)}
          {!isSuccess && (<button
            id="card-button"
            type="button"
            style={cardButtonStyles}
            //disabled={!isCardFieldsValid || isSubmitting|| !isVerified}
            disabled={!isCardFieldsValid || isSubmitting|| !isVerified || !orderConfirmed}
            onClick={() => handlePaymentMethodSubmission(squareCard)}
          >
            Pay {
            //paymentRequestMock.total.amount
            fullOrder.currency} {fullOrder.total_charges}
          </button>)}
          
        </div>
      {/* </form> */}
      <div
        id="payment-status-container"
      >
      </div>
          <button onClick={handleBackClick}>Back</button>
    </div>
  );
}
/*
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem"
      }}
*/