import axios from 'axios';

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

const currentTime=new Date().toLocaleString('en-US',{ timeZone: 'America/New_York' });
let dispDT = new Date(currentTime).toLocaleDateString();
let dispTM = new Date(currentTime).toLocaleTimeString();

//export const sendMail = (addresses, contents) => {
export const sendMail = (emailData) => {
    // the emailData consists of: from, to, cc, bcc, subject, text & html
    console.log(` @@  @@ sendMail - ${dispDT} ${dispTM} received emailData: `+JSON.stringify(emailData));
    const {from, to, cc, bcc, subject, text, html} = emailData||'';
    console.log(" @@ @@ sendMail - to, cc, bcc: ", to, cc, bcc);
    console.log(" @@ @@ sendMail - subject, text, html: ", subject, text, html);

    try {
        const response = axios.post(`https://${baseUrl}/api/sendemail/${to}`, {
            from: from,
            to: to,
            cc: cc,
            bcc: bcc,
            subject: subject,
            text: text,
            html: html,
        }); 
        console.log(" @@  @@ sendMail - response: "+JSON.stringify(response));
        return response
    } catch (error) {
        console.log(" @@  @@ sendMail - ERROR!!: "+JSON.stringify(error));
        return error
    }
}
