import React from 'react';
import { Link } from 'react-router-dom';

export const IndexPage = () => {
    return (
      <div className='container-fluid lh-sm fw-light px-5 mt-4'>

      <h1>Welcome to Fencing League of America</h1>

      <div className='row'>

          <div className='col-9'>



              <p></p>



          </div>


          <div className='col-3'>



              <p></p>



          </div>

      </div>


  </div>
    );
}
export default IndexPage;