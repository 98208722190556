import React, {Component} from "react";
import flaLogo from '../images/FLA-logo24x24.png';

class Navigation extends Component {
    render() {
        const { myprop }=this.props;
        console.log("@@@ in Nav (loggedIn): "+JSON.stringify(myprop));
        return (
<nav className="navbar navbar-expand-md bg-dark navbar-dark">
  <div className="container">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <a href="/" className="navbar-brand">
    <img src={flaLogo} alt="FLA logo" />
    {" "}Fencing League of America</a>
    <div className="collapse navbar-collapse" id="navbarText">
      <div className="navbar-nav ms-auto">
        {myprop && <a className="nav-item nav-link active" aria-current="page" href="/main">Main</a>}
        {myprop && <a className="nav-item nav-link" href="/profile">Profile</a>}
        {!myprop ? (<a href="/login" className="nav-item nav-link">Login</a>) : (<a href="/logout" className="nav-item nav-link">Logout</a>)}
        {!myprop ? (<a href="/signup" className="nav-item nav-link">Sign Up</a>) : ''}
      </div>
    </div>
  </div>
</nav>
        )
    }
}

export default Navigation;
/*
            <div className='topBanner' role="banner">
                <nav className="site-nav family-sans navbar navbar-expand bg-dark navbar-dark">
                    <div className="container">
                        <a href="/" className="navbar-brand">
                            <img src={flaLogo} alt="FLA logo" />
                            {" "}Fencing League of America
                        </a>
                        {myprop && (<a href="/main" className="nav-item nav-link">Main</a>)}
                        {myprop && (<a href="/profile" className="nav-item nav-link">Profile</a>)}
                        {!myprop ? (<a href="/login" className="nav-item nav-link">Login</a>) : (<a href="/logout" className="nav-item nav-link">Logout</a>)}
                        {!myprop ? (<a href="/signup" className="nav-item nav-link">Sign Up</a>) : ''}
                    </div>
                </nav>
            </div>
*/