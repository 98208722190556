// EventTable.js
// https://blog.logrocket.com/complete-guide-building-smart-data-table-react/

import React, { useState } from "react";
import { useTable, useFilters, useSortBy, useRowSelect } from "react-table";

export default function EventTable({ columns, data }) {

    const [filterInput, setFilterInput] = useState("");

    console.log(" @@@@ EventTable received data : ",data)
    console.log(" @@@@ EventTable received columns : ",columns)

    //const [selectedRows, setSelectedRows] = useState([]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["data.eventDays"] // https://github.com/TanStack/react-table/issues/1804
      }
    },
    useFilters,
    useSortBy,
    useRowSelect, // this needs to be placed after the "useSortBy" - otherwise, error - will tell you why in that error, though - then why can it automatically process it if it knows what is wrong?? lol
    hooks => {
        // https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/row-selection?file=/src/App.js:1301-2049
      hooks.visibleColumns.push(columns => [
        ...columns,
      ])
    }
  );


  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("item", value);
    setFilterInput(value);
  };

  // Render the UI for your table
  return (
    <>
      {
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"Search Event Name"}
      />
      }
      <table {...getTableProps()} style={{"width": "100%"}} id="eventsTable">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );

}