import { useHistory } from 'react-router-dom';
import futureChamps from "../images/smc-members-SML.jpg";

export const AboutPage = () => {
    const history = useHistory();

    return (
        <div className="container">
            <div className='topImage' >
                <img className="img-fluid position-relative" src={futureChamps} alt="future champions" style={{margin: "-20% 0px", width: "100%", clipPath: 'inset(27% 5% 30% 10% round 3%)'}}/>
            </div>
            <h1>About Fencing League of America</h1>
            <div className='row'>
                <div className='col-12'>

                    <h2>Who are we? Get to know us here.</h2>
                    <p>​​​​​​The Fencing League of America was created on the principle of making the beautiful sport of fencing easily accessible for anyone who wishes to practice it or teach it, regardless of age or level.​​</p>
<p>The Fencing League of America (FLA) uses a "state based" structure... and what does that mean?&nbsp;&nbsp;It means that we want to make sure that our athletes have access to the most important events and tournaments within their own states, without having to travel all over the country to qualify for our national championship.&nbsp;&nbsp;This system allows fencers to limit their travel within their home state, they only travel across states for the national championships once a year, and thus making it easier, safer and much more affordable.</p>

<p>The Fencing League of America (FLA) is also committed to helping clubs and coaches achieve their goals, both "athletic" and "professional".&nbsp;&nbsp;We want to make sure we are a helping hand.</p>

<p>Nobody knows how to run your own business better than yourself.&nbsp;&nbsp;We are doing this for the coaches, the clubs and the fencers, we want to hear your needs and suggestions and turn them into practical applications that make the sport better.</p>

<p>We believe in a small upstairs and a big basement.</p>


                        <blockquote className='text-end text-secondary'>&copy; 2022 Fencing League of America - All Rights Reserved</blockquote>

                </div>
            </div>
        </div>
    );
}