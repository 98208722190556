export const UserInfo = ({ user, classAct }) => {
    //const {name, age, hairColor, hobbies } = user || {};
    // classAct is for className to be used for output

    console.log("  @ UserInfo - classAct: "+classAct);
    if (user) {const { id, info } = user || {};
        console.log("  @ UserInfo - user was defined!");
        console.log("  @ UserInfo - user: "+JSON.stringify(user));
        return (
            <>
            <div className={classAct}>{info.memberId ? "#"+info.memberId : ''}</div>
            <div className={classAct}>{info.first ? info.first : ''}{" "}
            {info.middle ? info.middle : ''}{" "}
            {info.last ? info.last : ''}
            </div>
            </>
        ) 
    }else {
        return (
            <p>Loading...</p>
        )
    };
}