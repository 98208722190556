import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

export const withEditableProfile = (Component, userId) => {
	return props => {
        console.log("HOC/withEditableProfile - props: "+JSON.stringify(props));
		const [originalProfile, setOriginalProfile] = useState(null); // profile on the server so that we can reset back to the original values
		const [profile, setProfile] = useState(null); // profile being updated
		const token = localStorage.getItem('token');

		const [successMessage, setSuccessMessage] = useState(null);
		const [errorMessage, setErrorMessage] = useState(null);
		const [showSuccessMessage, setShowSuccessMessage] = useState(false);
		const [showErrorMessage, setShowErrorMessage] = useState(false);


	const history = useHistory();

		useEffect(() => {
			(async () => {
				// loading profile data
				const response = await axios.get(`https://${baseUrl}/api/profiles/${props.userId}`);
				console.log("HOC/withEditableProfile - axios.get response: "+JSON.stringify(response));
				console.log("HOC/withEditableProfile - axios.get response.data: "+JSON.stringify(response.data));
				//setOriginalProfile(response.data==="OK" ? [] : response.data);
				setOriginalProfile(response.data==="OK" ? [] : response.data['profile']);
				//setProfile(response.data==="OK" ? [] : response.data);
				setProfile(response.data==="OK" ? [] : response.data['profile']);
				console.log("HOC/withEditableProfile - profile set: "+JSON.stringify(profile));
				console.log("HOC/withEditableProfile - original profile set: "+JSON.stringify(originalProfile));
			})();
		//}, [props.userId]);
		}, []);

		const onChangeProfile = changes => {
			console.log("HOC/withEditableProfile - onChangeProfile - profile: "+JSON.stringify(profile));
			console.log("HOC/withEditableProfile - onChangeProfile - changes: "+JSON.stringify(changes));
			setProfile({ ...profile, ...changes });
		}

		const onCancelClicked = () => {
			history.goBack();
		}

		const onSaveProfile = async () => {
            console.log("HOC/withEditableProfile - profile being saved: "+JSON.stringify(profile));
            console.log("HOC/withEditableProfile - userId being used: "+JSON.stringify(props.userId));
            console.log("HOC/withEditableProfile - token being used: "+JSON.stringify(token));

			try{
				//
				const response = await axios.put(`https://${baseUrl}/api/updateprofiles/${props.userId}`, { profile }, {
					headers: { Authorization: `Bearer ${token}` }
				});
				console.log(">>> withEditableProfile - axios.post Saved and response.data: "+JSON.stringify(response.data));
				console.log(">>> withEditableProfile - We're gonna return just the profile of response.data");
				setOriginalProfile(response.data['profile']);
				setProfile(response.data['profile']);
				setSuccessMessage('Updated profile successfully.');
				setShowSuccessMessage(true);
			} catch(err) {
				//
				setErrorMessage('Login session may have expired. Update failed. Please logout once.');
				console.log("HOC/withEditableProfile <><><> err: "+JSON.stringify(err));
				console.log("HOC/withEditableProfile <><><> err.message: "+JSON.stringify(err.message));
				console.log("HOC/withEditableProfile <><><> err.stack: "+JSON.stringify(err.stack));
				//console.log("HOC/withEditableProfile <><><> err.config: "+JSON.stringify(err.config));
				setShowErrorMessage(true);
			}
		}

		const onResetProfile = () => {
			setProfile(originalProfile);
		}

		// return the original Component and all of props and new state variables like "profile"
		return <Component {...props}
			profile={profile}
			successMessage={successMessage}
			errorMessage={errorMessage}
			showSuccessMessage={showSuccessMessage}
			showErrorMessage={showErrorMessage}
			onChangeProfile={onChangeProfile}
			onSaveProfile={onSaveProfile}
			onResetProfile={onResetProfile}
			onCancelClicked={onCancelClicked} />
	}
}