import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}

export const Receipt = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const { receipt } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const receiptInfo = async () => {
      try {
        //const result = await axios("https://api.tvmaze.com/search/shows?q=snow");
        const result = await axios(`https://${baseUrl}/api/receipts/${props.userId}/${receipt}`);

        console.log("result: ", result);
        console.log("result.data: ", result.data);
        console.log("result.data.message: ", result.data.message);
        setData(result.data.message);

        setIsSuccess(true);
        setIsLoading(false);
      } catch (e) {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };

    receiptInfo();
  }, [setData]);

  console.log("Receipt - receiptId:", receipt);
  console.log("Receipt - data:", data);
  if (isLoading) return <p>Loading...</p>;
  if (!isSuccess) return <p>not success</p>;

  return (
    <>
      {
        data.map((receipt, key) => {
          // the createdAt is probably string and need to convert to actual Date
          let myDate = new Date(receipt.data.createdAt).toLocaleDateString();
          let myTime = new Date(receipt.data.createdAt).toLocaleTimeString();
          return (
            <>
              <div className='container lh-sm px-5 mt-4'>
                  <h1>Receipt for Order ID {receipt.data.orderId}</h1>
              <div className="card text-dark" style={{"width":"100%"}}>
                <div className="card-header px-2 pt-1">
                  Order Summary - Receipt ID: "{receipt.data.receiptNumber}" (Order ID: {receipt.data.orderId})
                </div>
                <div className="card-title px-1 my-2">Total Items: {receipt.data.fullOrder.total_items}</div>
                <div className="card-subtitle px-2 my-2">
                  {myDate} {myTime}
                  {" - "}
                  {receipt.data.sourceType} - {receipt.data.cardBrand} (last 4-digit: {receipt.data.last4})
                </div>

                <div className="card-body">
                    <table className="table">
                        <thead><tr><th style={{"width":"60%"}}>Item</th><th style={{"width":"20%"}}>Currency</th><th style={{"width":"30%"}}>Price</th></tr></thead>
                        <tfoot><tr><th style={{"width":"60%"}}>Total:</th><th className='text-end' style={{"width":"20%"}}>{receipt.data.fullOrder.currency}</th><th className='text-end' style={{"width":"30%"}}>{receipt.data.fullOrder.total_charges.toFixed(2)}</th></tr></tfoot>
                        <tbody>
                  {receipt.data.fullOrder.line_items.map((item, key) => {
                    //
                    let myPrice = Number(item.price).toFixed(2);
                    return (
                      <>
                        <tr>
                            <td>{item.id}</td>
                            <td className="text-end">{item.currency}</td>
                            <td className="text-end">{myPrice}</td>
                        </tr>
                      </>
                    );
                  })}</tbody>
                  </table>
                </div>
                </div>
              </div>



              <p className="text-success fs-2 fw-bold bg-light text-center mt-5 mx-5">Thank You!</p>
            </>
          );
        }) //map
      }
    </>
  );
};

