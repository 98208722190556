import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import purify from "dompurify";
import { useUser } from "../../../front-end/src/auth/useUser";
import EventTable from "./components/EventTable";

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}


//export const Event = ({ classAct, event }) => {
export const Event = (props) => {
  // export const Event = (props) => {
  console.log(" @ Event - props: ", props);
  const { eventId } = useParams();
  console.log(" @ Event - eventId: ", eventId);

  const history = useHistory();
  const user = props.userId;

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [events, setEvents] = useState([]);
  const [isSkipp, setIsSkipp] = useState(false);
  const [eventsEventFees, setEventsEventFees] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Events",
        columns: [
          {
            Header: "Event",
            accessor: "item",
          },
           {
             Header: "Type",
             //accessor: '', // custom columns  https://www.javaer101.com/en/article/40725321.html
             Cell: (row) => {
               console.log(" @@@@ Event - row #1: ", row);
               let value = row.row.original.item;
               console.log(" @@@@ Event - value #1: ", value);
               let dispValue = '';
               if (value.match(/.*foil*/i)) {dispValue='Foil'}
               if (value.match(/.*[eé]p[eé]e*/i)) {dispValue='Épée'}
               if (value.match(/.*saber*/i)) {dispValue='Saber'}
               return <>{dispValue}</>
             }
             ,
             accessor: row => { // basically we need proper values in accessor for sorting
               let dispValue = '';
               if (row.item.match(/.*foil*/i)) {dispValue='Foil'}
               if (row.item.match(/.*[eé]p[eé]e*/i)) {dispValue='Épée'}
               if (row.item.match(/.*saber*/i)) {dispValue='Saber'}
               return dispValue
             }
           }
           ,
           {
             Header: "Gender",
             //accessor: '', // NG: need empty accessor for sorting to be enabled https://stackoverflow.com/questions/63227603/react-table-sorting-not-working-for-column-with-custom-cell
             accessor: row => {
               return row.gender
             },
             Cell: (row) => {
               let value = row.row.original;
               switch (value.gender) {
                 case 'N/A':
                   return (<>N/A</>)
                 case 'M':
                   return (<>Male</>)
                 case 'F':
                   return (<>Female</>)
                 case 'C':
                   return (<>CoEd</>)
                 default:
                   return (<>N/A</>)
               }
             }
           }
           ,
           {
             Header: "Start Date/Time",
             accessor: row => {
               return row.startDateTime
             },
             Cell: (row) => {
               let value = row.row.original.startDateTime;
               if (value !== 'NA'){
                 let startDate = new Date(value).toLocaleDateString();
                 let startTime = new Date(value).toLocaleTimeString([],{hour: '2-digit', minute: '2-digit'}); // removed the "seconds"
                 return (
                   <>
                     {startDate} {startTime}
                   </>
                 )
               } else return <>N/A</>
             }
           },
           {
             Header: "Fee",
             accessor: row => {
               return row.price; // we don't need the currency
             },
             Cell: (row) => {
               //console.log("  @ @ @ @ @ EventTable - Fee - row:", row);
               let value = row.row.original;
               return (
                 <>
                   {value.currency}&nbsp;{value.price}
                 </>
               )
             }
           },
           {
             Header: "Required?",
             accessor: row => {
               return row.required
             },
             Cell: (row) => {
               //
               let value = row.row.original;
               //let newRequiredItemList=null;
               // newUpdatedRequiredList = newUpdatedRequiredList.filter(
               //     (reqItem) => !(reqItem.itemId == item.id)
               // );

               return (
                 <>
                   {value.required?<span className="text-danger fw-bold">Yes</span>:"No"}
                 </>
               )
             }
           }
        ],
      },
    ],
    []
  );

  useEffect(() => {
    const loadEvents = async () => {
      console.log("Event - useEffect");
      try {
        const response = await axios.get(`https://${baseUrl}/api/events/${eventId}`);
        console.log(" Event - response: ", response);
        const responseData = response.data.message;
        console.log(" Event - responseData: ", responseData);
        console.log(" Event - responseData[0]: ", responseData[0]);
        console.log(" Event - responseData[0].data: ", responseData[0].data);
        if (responseData === "No data found.") setIsSkipp(true);
        setEvents(responseData);
        setEventsEventFees(responseData[0].data.eventFees);
        setIsSuccess(true);
        setIsLoading(false);
      } catch (e) {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };

    loadEvents();
  }, [user]);

  const ShowDays = ({eventStartDate, eventDays}) => {
    let startDate = new Date(eventStartDate); //toLocaleDateString();
    console.log("startDate: ",startDate);
    let duration = eventDays;
    startDate.setDate(startDate.getDate());
    let myStartDate = startDate.toLocaleDateString();
    //let myLastDate = startDate.setDate(startDate.getDate() + duration );

    startDate.setDate(startDate.getDate() + duration - 1);
    let myLastDate = startDate.toLocaleDateString();

const timezoneOffset = (new Date()).getTimezoneOffset();
console.log("timezoneOffset",timezoneOffset);
console.log("timezone: ", Intl.DateTimeFormat().resolvedOptions().timeZone);


    return  <span>{myStartDate} - {myLastDate}</span>
  };

  if (isLoading) return <p>Loading...</p>;
  if (isSkipp) return <p>No prior transactions found</p>;
  if (isSuccess) {
    console.log(" Event - events to be processed: ", events);


    function GetHTMLDisplayData(props) {
      console.log("  @@ Event - GetHTMLDisplayData - props.data", props.data);
      if (props.data !=="") {
        return <div dangerouslySetInnerHTML={{ __html: purify.sanitize(props.data) }} />
      }
    }

    // nexted array: https://stackoverflow.com/questions/51615559/react-map-over-nested-array-of-objects
    return (
      <>
        <div className="container">
          <div className="card text-dark">
            {events.map((event, key) => (
              <>
                <div className="card-header">
                    #{event.data.eventId} - <GetHTMLDisplayData data={event.data.eventTitleHTML} />
                </div>
                <div className="card-body">

                  <GetHTMLDisplayData data={event.data.eventDescriptionsHTML1} />
                  <GetHTMLDisplayData data={event.data.eventDescriptionsHTML2} />
                  <GetHTMLDisplayData data={event.data.eventDescriptionsHTML3} />
                  <GetHTMLDisplayData data={event.data.eventDescriptionsHTML4} />
                  <GetHTMLDisplayData data={event.data.eventDescriptionsHTML5} />
                <br/>
                  <div className="d-flex justify-content-center d-inline-block">
                    <Link className="btn btn-primary btn-lg ms-5"  to={{ pathname: `/eventpayment/${eventId}`}} >Register &amp; Pay</Link>
                  </div>

                  <div className="d-flex justify-content-center pt-5"><button className="btn btn-sm text-light bg-success" onClick={() => history.goBack()}>Back</button></div>

                  <hr/>
                  <h3>Events &amp; Fees</h3>

                  <EventTable columns={columns} data={eventsEventFees} />

                </div>
              </>
            ))}
                  <div className="d-flex justify-content-center d-inline-block pb-5">
                    <Link className="btn btn-primary btn-lg ms-5"  to={{ pathname: `/eventpayment/${eventId}`}} >Register &amp; Pay</Link>
                  </div>
          </div>
        <div className="d-flex justify-content-center mt-5"><button className="btn btn-sm text-light bg-success" onClick={() => history.goBack()}>Back</button></div>
        </div>
      </>
    );
  } else {
    return <p className="text-danger bg-warning">...</p>;
  }
};
export default Event;