// The UserProfilePage
//   We're not using "form" in this functional module.

import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useToken } from "../auth/useToken";
import { useUser } from "../auth/useUser";
import { useUserData } from "../auth/useUserData";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import validator from "validator";

import "./css/UserProfilePage.css";

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}

const disableDob = true; ///////// flag to disable DoB updates
//console.log(" @@ UserProfilePage - disableDob: " + disableDob);
//console.log(" @@ UserProfilePage - baseUrl: " + baseUrl);

export const UserProfilePage = (props) => {
  //const {profileData} = useParams(); // don't forget to import useParams

  console.log("@@ UserProfilePage - PROPS : " + JSON.stringify(props));

  //export const UserProfilePage = ( profiled ) => {
  const user = useUser();
  const [token, setToken] = useToken();
  const [successStep1, setSuccessStep1] = useState(false);

  const [dobError, setDobError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [fieldEntryError, setFieldEntryError] = useState("");
  const [firstError, setFirstError] = useState("");
  const [lastError, setLastError] = useState("");
  const [streetAddress1Error, setStreetAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [contactNameError, setContactNameError] = useState("");
  const [contactKindError, setContactKindError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [aauNumberError, setAauNumberError] = useState("");

  // https://github.com/validatorjs/validator.js
  const validateDob = (e) => {
    var dob = e.target.value;
    //if (!validator.isDate(dob,'MM/DD/YYYY','/')){
    if (!validator.isDate(dob)) {
      //setDobError('Enter Date of Birth in the format of MM/DD/YYY');
      setDobError("Enter proper date of birth");
    } else {
      setDobError("");
    }
  };

  const validatePhone = (e) => {
    var phone = e.target.value;
    if (!validator.isMobilePhone(phone)) {
      setPhoneError("Enter Valid Phone number");
    } else {
      setPhoneError("");
    }
  };

  const validateContactNumber = (e) => {
    var contactNumber = e.target.value;
    if (!validator.isMobilePhone(contactNumber)) {
      setContactNumberError("Enter Valid Phone number");
    } else {
      setContactNumberError("");
    }
  };

  const validateFirst = (e) => {
    var first = e.target.value;
    if (validator.isEmpty(first)) {
      setFirstError("Enter Valid value - it is required.");
    } else {
      setFirstError("");
    }
  };
  const validateLast = (e) => {
    var last = e.target.value;
    if (validator.isEmpty(last)) {
      setLastError("Enter Valid value - it is required.");
    } else {
      setLastError("");
    }
  };
  const validateStreetAddress1 = (e) => {
    var streetAddress1 = e.target.value;
    if (validator.isEmpty(streetAddress1)) {
      setStreetAddress1Error("Enter Valid value - it is required.");
    } else {
      setStreetAddress1Error("");
    }
  };
  const validateCity = (e) => {
    var city = e.target.value;
    if (validator.isEmpty(city)) {
      setCityError("Enter Valid value - it is required.");
    } else {
      setCityError("");
    }
  };
  const validateState = (e) => {
    var state = e.target.value;
    if (validator.isEmpty(state)) {
      setStateError("Enter Valid value - it is required.");
    } else {
      setStateError("");
    }
  };
  //const validatePostalCode = (e) => {var postalCode = e.target.value; if (validator.isEmpty(postalCode)){setPostalCodeError('Enter Valid value - it is required.');} else {setPostalCodeError('')}}
  const validatePostalCode = (e) => {
    var postalCode = e.target.value;
    if (!validator.isPostalCode(postalCode, country)) {
      setPostalCodeError("Enter Valid Postal Code - it is required.");
    } else {
      setPostalCodeError("");
    }
  };
  const validateCountry = (e) => {
    var country = e.target.value;
    if (validator.isEmpty(country)) {
      setCountryError("Enter Valid value - it is required.");
    } else {
      setCountryError("");
    }
  };
  const validateContactName = (e) => {
    var contactName = e.target.value;
    if (validator.isEmpty(contactName)) {
      setContactNameError("Enter Valid value - it is required.");
    } else {
      setContactNameError("");
    }
  };
  const validateContactKind = (e) => {
    var contactKind = e.target.value;
    if (validator.isEmpty(contactKind)) {
      setContactKindError("Enter Valid value - it is required.");
    } else {
      setContactKindError("");
    }
  };
  const validateGender = (e) => {
    var gender = e.target.value;
    if (validator.isEmpty(gender)) {
      setGenderError("Enter Valid value - it is required.");
    } else {
      setGenderError("");
    }
  };

  const validateFieldEntry = (e) => {
    var fieldEntry = e.target.value;
    if (validator.isEmpty(fieldEntry)) {
      setFieldEntryError("Enter Valid value - it is required.");
    } else {
      setFieldEntryError("");
    }
  };

  console.log("@@ UserProfilePage - successStep1 (top) default false: " + successStep1);

  console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  console.log("@@ UserProfilePage - type of user: " + typeof user);
  console.log("@@ UserProfilePage - user: " + JSON.stringify(user));

  const { id, email, isVerified, info, tobi } = user; // destructuring assignment

  console.log("@@ >> destructured id: " + id + " email: " + email + " isVerified: " + isVerified);
  console.log("@@ >> destructured info: " + JSON.stringify(info) + " tobi: " + tobi + " << @@");

  // We'll use the history to navigate the user
  // programmatically. after push, we'd need go() for forced refresh.
  const history = useHistory();

  const userData = useUserData(id);
  console.log(" @@@@@@ >> UserProfilePage - userData Loaded: " + JSON.stringify(userData) + " << @@@@@@ ");

  const { errorMessage } = userData;
  if (errorMessage === "bad request?") {
    console.log(" @@@@@@ >> UserProfilePage - SERIOUS ISSUE detected!");
    // issues with id in DB. either user no longer exists or other serious issue...
    history.push("/logout");
  }

  //console.log("  @@@@@ UserProfilePage - info.bio: "+info.bio)
  console.log("  @@@@@ UserProfilePage - userData.bio: " + userData.bio);

  // These states are bound to the values of the text inputs
  // on the page (see JSX below).
  //    const [favoriteFood, setFavoriteFood] = useState(info.favoriteFood || '');
  //    const [hairColor, setHairColor] = useState(info.hairColor || '');
  //    const [bio, setBio] = useState(info.bio || '');

  const [first, setFirst] = useState(info.first || "");
  console.log("@@ -----> UserProfilePage - ValueCheck first: " + first + " & info.first: " + info.first);
  const [last, setLast] = useState(info.last || "");
  const [middle, setMiddle] = useState(info.middle || "");
  ////const [dob, setDob] = useState(info.dob || '');

  console.log("@@ UserProfilePage - userData: " + JSON.stringify(userData));

  useEffect(() => {
    console.log(" @@ UserProfilePage - in useEffect");
    console.log(" @@ UserProfilePage - in useEffect spot check - userData: " + JSON.stringify(userData));
    //
    //    setFavoriteFood(userData.favoriteFood);
    //    setHairColor(userData.hairColor);
    //    setBio(userData.bio);
    setDob(userData.dob);
    setPhone(userData.phone);
    setStreetAddress1(userData.streetAddress1);
    setStreetAddress2(userData.streetAddress2);
    setCity(userData.city);
    setState(userData.state);
    setPostalCode(userData.postalCode);
    setCountry(userData.country);
    setContactName(userData.contactName);
    setContactKind(userData.contactKind);
    setContactNumber(userData.contactNumber);
    setDominantHand(userData.dominantHand);
    setSchool(userData.school);
    setGender(userData.gender);
    setAauNumber(userData.aauNumber);
  }, [userData]);

  const [dob, setDob] = useState(userData.dob || "");
  const [phone, setPhone] = useState(userData.phone || "");
  const [streetAddress1, setStreetAddress1] = useState(userData.streetAddress1 || "");
  const [streetAddress2, setStreetAddress2] = useState(userData.streetAddress2 || "");
  const [city, setCity] = useState(userData.city || "");
  const [state, setState] = useState(userData.state || "");
  const [postalCode, setPostalCode] = useState(userData.postalCode || "");
  const [country, setCountry] = useState(userData.country || "");
  const [contactName, setContactName] = useState(userData.contactName || "");
  const [contactKind, setContactKind] = useState(userData.contactKind || "");
  const [contactNumber, setContactNumber] = useState(userData.contactNumber || "");
  const [dominantHand, setDominantHand] = useState(userData.dominantHand || "");
  const [school, setSchool] = useState(userData.school || "");
  const [gender, setGender] = useState(userData.gender || "");
  const [aauNumber, setAauNumber] = useState(userData.aauNumber || "");
  //const [requestBody, setRequestBody] = useState([]);
  const [requestBody, setRequestBody] = useState({});

  console.log("@@ UserProfilePage - id: " + id);
  const [userId, setUserId] = useState(id); // defaulted to "id"
  console.log("@@ UserProfilePage - userId: " + userId);

  // These state variables control whether or not we show
  // the success and error message sections after making
  // a network request (see JSX below).
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const selectCountry = (val) => {
    console.log("selectCountry - val: " + val);
    //setCCountry( val );
    //onChangeProfile( {country: cCountry} );
    //onChangeProfile( {country: val} );
    setCountry(val);
    console.log("selectCountry - country: " + country);
  };

  const selectRegion = (val) => {
    console.log("selectRegion - val: " + val);
    //setRegion( val );
    //onChangeProfile( {state: region} );
    //onChangeProfile( {state: val} );
    //setRegionValue( val );
    setState(val);
    console.log("selectRegion - state: " + state);
  };

  // This useEffect hook automatically hides the
  // success and error messages after 3 seconds when they're shown.
  useEffect(() => {
    if (showSuccessMessage || showErrorMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        //history.go();
        history.goBack();
      }, 3000);
    }
  }, [showSuccessMessage, showErrorMessage]);

  const saveChanges = async () => {
    if (isVerified) {
    // basically we want to process two transactions:
    // 1. "users" collection document. upon successful response, then
    // 2. "profiles" collection document.
    console.log(" @@ UserProfilePage - saveChanges()");
    console.log(" @@ UserProfilePage - token being sent as Authorization: " + token);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - first: " + first);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - last: " + last);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - middle: " + middle);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - dob: " + dob);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - phone: " + phone);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - streetAddress1: " + streetAddress1);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - streetAddress2: " + streetAddress2);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - city: " + city);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - state: " + state);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - postalCode: " + postalCode);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - country: " + country);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - contactName: " + contactName);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - contactKind: " + contactKind);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - contactNumber: " + contactNumber);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - dominantHand: " + dominantHand);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - school: " + school);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - gender: " + gender);
    console.log(" @@ UserProfilePage - spot check before setRequestBody - aauNumber: " + aauNumber);
    setRequestBody({
      first,
      last,
      middle,
      dob,
      phone,
      streetAddress1,
      streetAddress2,
      city,
      state,
      postalCode,
      country,
      contactName,
      contactKind,
      contactNumber,
      dominantHand,
      school,
      gender,
      aauNumber,
    }); // requestBody
    console.log(" @@ UserProfilePage.js - requestBody: " + JSON.stringify(requestBody));
    // not sure why requestBody no longer gets populated via setRequestBody()

    const reqBody = { first, last, middle, dob, phone, streetAddress1, streetAddress2, city, state, postalCode, country, contactName, contactKind, contactNumber, dominantHand, school, gender, aauNumber };

    console.log(" @@ UserProfilePage.js - reqBody: " + JSON.stringify(reqBody));
    console.log(" @@ UserProfilePage.js - we're gonna use reqBody @@@@@@@@@@@@@@");

    try {
      // we won't update memberId
      const response = await axios.put(
        `https://${baseUrl}/api/users/${id}`,
        {
          //requestBody
          reqBody,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("  @@@ userProfilePage - first axios done");

      console.log("  @@@ userProfilePage - response.data to be tokenized: " + JSON.stringify(response.data));
      const { token: newToken } = response.data;
      setToken(newToken);

      setSuccessStep1(true); // setting the flag to indicate step #1 is successful
      console.log(" @@ UserProfilePage - successStep1: " + successStep1);
      console.log(" @@ UserProfilePage - first axios' response.data: " + JSON.stringify(response.data));
      //console.log("@@ UserProfilePage - responseProfile.data: "+JSON.stringify(responseProfile.data));

      if (successStep1) {
        // step #1 was successful
        console.log(" @@ UserProfilePage - Step #1 success!");
      } else {
        // step # was not successful
        console.log(" @@ UserProfilePage - Step #1 FAILED!!!");
      }

      setShowSuccessMessage(true); // NOT YET HERE - after step #2
    } catch (error) {
      setShowErrorMessage(true);
    }
  }// if (isVerified)
  }; // saveChanges()

  const handleBackClick = () => {
    history.goBack();
  };

  const resetValues = () => {
    console.log(" @@ UserProfilePage - in resetValues - userData: " + JSON.stringify(userData));
    /*
        if (userData.first===undefined){setFirst('');}else{setFirst(userData.first);}
        if (userData.last===undefined){setLast('');}else{setLast(userData.last);}
        if (userData.middle===undefined){setMiddle('');}else{setMiddle(userData.middle);}

        if (userData.dob===undefined){setDob('');}else{setDob(userData.dob);}
        if (userData.phone===undefined){setPhone('');}else{setPhone(userData.phone);}
        if (userData.streetAddress1===undefined){setStreetAddress1('');}else{setStreetAddress1(userData.streetAddress1);}
        if (userData.streetAddress2===undefined){setStreetAddress2('');}else{setStreetAddress2(userData.streetAddress2);}
        if (userData.city===undefined){setCity('');}else{setCity(userData.city);}
        if (userData.state===undefined){setState('');}else{setState(userData.state);}
        if (userData.postalCode===undefined){setPostalCode('');}else{setPostalCode(userData.postalCode);}
        if (userData.country===undefined){setCountry('');}else{setCountry(userData.country);}
        if (userData.contactName===undefined){setContactName('');}else{setContactName(userData.contactName);}
        if (userData.contactKind===undefined){setContactKind('');}else{setContactKind(userData.contactKind);}
        if (userData.contactNumber===undefined){setContactNumber('');}else{setContactNumber(userData.contactNumber);}
        if (userData.dominantHand===undefined){setDominantHand('');}else{setDominantHand(userData.dominantHand);}
        if (userData.school===undefined){setSchool('');}else{setSchool(userData.school);}
        if (userData.gender===undefined){setGender('');}else{setGender(userData.gender);}
        if (userData.aauNumber===undefined){setAauNumber('');}else{setAauNumber(userData.aauNumber);}
        */
    if (userData.first === undefined || userData.first === null) {
      setFirst("");
    } else {
      setFirst(userData.first);
    }
    if (userData.last === undefined || userData.last === null) {
      setLast("");
    } else {
      setLast(userData.last);
    }
    if (userData.middle === undefined || userData.middle === null) {
      setMiddle("");
    } else {
      setMiddle(userData.middle);
    }
    if (userData.dob === undefined || userData.dob === null) {
      setDob("");
    } else {
      setDob(userData.dob);
    }
    if (userData.phone === undefined || userData.phone === null) {
      setPhone("");
    } else {
      setPhone(userData.phone);
    }
    if (userData.streetAddress1 === undefined || userData.streetAddress1 === null) {
      setStreetAddress1("");
    } else {
      setStreetAddress1(userData.streetAddress1);
    }
    if (userData.streetAddress2 === undefined || userData.streetAddress2 === null) {
      setStreetAddress2("");
    } else {
      setStreetAddress2(userData.streetAddress2);
    }
    if (userData.city === undefined || userData.city === null) {
      setCity("");
    } else {
      setCity(userData.city);
    }
    if (userData.state === undefined || userData.state === null) {
      setState("");
    } else {
      setState(userData.state);
    }
    if (userData.postalCode === undefined || userData.postalCode === null) {
      setPostalCode("");
    } else {
      setPostalCode(userData.postalCode);
    }
    if (userData.country === undefined || userData.country === null) {
      setCountry("");
    } else {
      setCountry(userData.country);
    }
    if (userData.contactName === undefined || userData.contactName === null) {
      setContactName("");
    } else {
      setContactName(userData.contactName);
    }
    if (userData.contactKind === undefined || userData.contactKind === null) {
      setContactKind("");
    } else {
      setContactKind(userData.contactKind);
    }
    if (userData.contactNumber === undefined || userData.contactNumber === null) {
      setContactNumber("");
    } else {
      setContactNumber(userData.contactNumber);
    }
    if (userData.dominantHand === undefined || userData.dominantHand === null) {
      setDominantHand("");
    } else {
      setDominantHand(userData.dominantHand);
    }
    if (userData.school === undefined || userData.school === null) {
      setSchool("");
    } else {
      setSchool(userData.school);
    }
    if (userData.gender === undefined || userData.gender === null) {
      setGender("");
    } else {
      setGender(userData.gender);
    }
    if (userData.aauNumber === undefined || userData.aauNumber === null) {
      setAauNumber("");
    } else {
      setAauNumber(userData.aauNumber);
    }
  };

  ////////////////////
  if (user) {
    ///////////////////
    if (first === undefined) return <p>loading....</p>;
    // And here we have the JSX for our component. It's pretty straightforward
    return (
      <div className="container-fluid lh-sm fw-light px-5 mt-4">
        <h1>Profile Page</h1>
        <p className="text-warning p-2">Incomplete/inaccurate information on your profile may lead to delays, disqualifications and/or unnacceptance of your event registrations and results.</p>
        <div className="row">
          <div className="col-12">
            <>
              <h2>
                #{info.memberId}: {first} {middle} {last} {email}
              </h2>
              <hr />
              <p className="">
                <span className="text-danger fs-1"> * </span> denotes required field.
              </p>
              <hr />
              {!isVerified && <div className="text-danger p-2 m-1 bg-warning">You won't be able to make any changes until you verify your email</div>}
              {showSuccessMessage && <div className="success">Successfully saved user data!</div>}
              {showErrorMessage && <div className="text-danger">Uh oh... something went wrong and we couldn't save changes</div>}
              <div className="card text-black p-2">
              <label>
                First Name<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setFirst(e.target.value);
                    validateFirst(e);
                  }}
                  value={first}
                  maxLength="80"
                  required
                />
                {firstError && <p className="text-danger bg-warning">{firstError}</p>}
              </label>
              <label>
                Middle:
                <input onChange={(e) => setMiddle(e.target.value)} value={middle} size="6" maxLength="5" />
              </label>
              <label>
                Last Name<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setLast(e.target.value);
                    validateLast(e);
                  }}
                  value={last}
                  maxLength="80"
                  required
                />
                {lastError && <p className="text-danger bg-warning">{lastError}</p>}
              </label>
              <label>
                Date of Birth <span className="text-danger">*</span>:
                <input
                  type="date"
                  onChange={(e) => {
                    setDob(e.target.value);
                    validateDob(e);
                  }}
                  value={dob}
                  required
                />
                <span className="text-danger">{dobError}</span>
              </label>
              <label>
                Gender<span className="text-danger">*</span>:{" "}
                <select
                  name="gender"
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                    validateGender(e);
                  }}
                  required
                >
                  <option value="">Choose...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </label>
              <hr />
              <label>
                Phone<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setPhone(e.target.value);
                    validatePhone(e);
                  }}
                  value={phone}
                  placeholder="000-000-0000"
                  required
                />
                {phoneError && <p className="text-danger bg-warning">{phoneError}</p>}
              </label>
              <label>
                Street Address 1<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setStreetAddress1(e.target.value);
                    validateStreetAddress1(e);
                  }}
                  value={streetAddress1}
                  required
                />
                {streetAddress1Error && <p className="text-danger bg-warning">{streetAddress1Error}</p>}
              </label>
              <label>
                Street Address 2:
                <input onChange={(e) => setStreetAddress2(e.target.value)} value={streetAddress2} />
              </label>
              <label>
                City:
                <input
                  onChange={(e) => {
                    setCity(e.target.value);
                    validateCity(e);
                  }}
                  value={city}
                  required
                />
                {cityError && <p className="text-danger bg-warning">{cityError}</p>}
              </label>
              <label>
                Country<span className="text-danger">*</span>:<CountryDropdown value="short" value={country} onChange={(val) => selectCountry(val)} valueType="short" required />
                {countryError && <p className="text-danger bg-warning">{countryError}</p>}
              </label>{" "}
              <label>
                State/Region<span className="text-danger">*</span>: <RegionDropdown country={country} value={state} onChange={(val) => selectRegion(val)} countryValueType="short" required />
                {stateError && <p className="text-danger bg-warning">{stateError}</p>}
              </label>
              <label>
                Postal Code<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                    validatePostalCode(e);
                  }}
                  value={postalCode}
                  required
                />
                {postalCodeError && <p className="text-danger bg-warning">{postalCodeError}</p>}
              </label>
              <style display="none">
                <label>
                  state:
                  <input onChange={(e) => setState(e.target.value)} value={state} />
                </label>
                <label>
                  country:
                  <input onChange={(e) => setCountry(e.target.value)} value={country} />
                </label>
              </style>
              <hr />
              <label>
                Dominant Hand:{" "}
                <select name="dominantHand" value={dominantHand} onChange={(e) => setDominantHand(e.target.value)}>
                  <option value="">Choose...</option>
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                </select>
              </label>
              <label>
                School:
                <input onChange={(e) => setSchool(e.target.value)} value={school} />
              </label>
              <label>
                AAU #:
                <input onChange={(e) => setAauNumber(e.target.value)} value={aauNumber} />
              </label>
              <hr />
              <label>
                Emergency Contact Name<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setContactName(e.target.value);
                    validateContactName(e);
                  }}
                  value={contactName}
                  required
                />
                {contactNameError && <p className="text-danger bg-warning">{contactNameError}</p>}
              </label>
              <label>
                Emergency Contact Kind<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setContactKind(e.target.value);
                    validateContactKind(e);
                  }}
                  value={contactKind}
                  placeholder="Father, Mother, etc"
                  required
                />
                {contactKindError && <p className="text-danger bg-warning">{contactKindError}</p>}
              </label>
              <label>
                Emergency Contact Number<span className="text-danger">*</span>:
                <input
                  onChange={(e) => {
                    setContactNumber(e.target.value);
                    validateContactNumber(e);
                  }}
                  value={contactNumber}
                  placeholder="000-000-0000"
                  required
                />
                {contactNumberError && <p className="text-danger bg-warning">{contactNumberError}</p>}
              </label>
              </div>
              <hr />
              {!isVerified && <div className="text-danger p-2 m-1 bg-warning">You won't be able to make any changes until you verify your email</div>}
              {showSuccessMessage && <><div className="success">Successfully saved user data!</div><hr/></>}
              {showErrorMessage && <div className="text-danger">Uh oh... something went wrong and we couldn't save changes</div>}
              <div className="d-flex">
                <button className="btn btn-sm bg-success text-light mx-3 my-2" onClick={resetValues}>
                  Reset Values
                </button>
                <button className="btn btn-sm bg-success text-light mx-3 my-2" onClick={handleBackClick}>
                  Cancel/Back
                </button>
                <button
                  className="btn btn-sm bg-light mx-3 my-2"
                  disabled={firstError || lastError || phoneError || dobError || streetAddress1Error || cityError || stateError || postalCodeError || countryError || contactNameError || contactKindError || contactNumberError || genderError || !isVerified}
                  onClick={saveChanges}
                >
                  Save Changes
                </button>
              </div>
              <hr />
            </>
          </div>
        </div>
      </div>
    );
  } // if (myProfile)
  else {
    return <p>loading....</p>;
  }
};
//<MyInfoPage id={userId} usafNumber={usafNumber} />

//<MyProfileInfoPage id={userId}/>
/*
            <label>
                Favorite Food:
                <input
                    onChange={e => setFavoriteFood(e.target.value)}
                    value={favoriteFood} />
            </label>
            <label>
                Hair Color:
                <input
                    onChange={e => setHairColor(e.target.value)}
                    value={hairColor} />
            </label>
            <label>
                Bio:
                <input
                    onChange={e => setBio(e.target.value)}
                    value={bio} />
            </label>
            */
//<h2>#{info.memberId}: {first} {middle} {last} {email} {new Date(dob).toLocaleString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"})}.</h2>

