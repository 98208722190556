import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const LogOutPage = () => {

    const [errorMessage, setErrorMessage] = useState('');

    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            history.push('/');
            history.go();
        }, 3000);
    }, [history]);

    // Logging out
    localStorage.removeItem('token');
    //history.push('/login');

    return (
        <div className="content-container">
            <h1>You've been Logged Out</h1>
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </div>
    );
}