import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const PleaseVerifyEmailPage = () => {
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            history.push('/main');
            history.go();
        }, 6000);
    }, [history]);

    return (
        <div className="content-container">
            <h1>Thanks for Signing Up!</h1>
            <p>
                A verification email has been sent to the email address you provided.
                Please verify your email to unlock full site features.
            </p>
            <p>If you do not receive the email within a reasonable time-frame from supportdesk@FencingLeagueOfAmerica.com, please check your SPAM folder.</p>
        </div>
    );
}