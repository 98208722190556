import { useState, useEffect } from 'react';
import axios from 'axios';

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

export const useUserData = (userId) => {

    const [userData, setUserData] = useState([]);

    console.log("   auth/useUserData *********");

    const url = `https://${baseUrl}/api/users/`+userId;
    console.log("   auth/useUserData - userId received: "+userId);
    console.log("   auth/useUserData - url: "+url);
/*
    useEffect(() => {
        axios.get(url)
        //リクエストに成功した場合
        .then(res =>{
            //setUserData(res);
            setUserData(res.data["profile"]);
        })
        //リクエストに失敗した場合catchの中に入ってくる
        .catch(err => {
            setUserData([]);
            console.log("   auth/useUserData - request failed...");
        })
    },[])
*/

    console.log("   auth/useUserData - get ready!");

useEffect(function() {
    console.log("   auth/useUserData - in useEffect()");
    async function getUserData() {
        try {
            const response = await axios.get(url); 
            console.log("   auth/useUserData - in useEffect - response.data being setUserData: "+JSON.stringify(response.data));
            setUserData(response.data);
        } catch(error) {
            console.log("   auth/useUserData - request failed...");
        }
    }
    getUserData();
}, []);




    console.log("   auth/useUserData - typeof userData: "+typeof(userData));
    console.log("   auth/useUserData - userData being returned-: "+JSON.stringify(userData));


    return userData; 
}
