import React from 'react';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../../../front-end/src/auth/useUser';

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

export const ReceiptsList = ({classAct}) =>{

    const user = useUser();
    console.log("ReceiptsList - user: "+JSON.stringify(user));

    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [receipts, setReceipts] = useState([]);
    const [isSkipp, setIsSkipp] = useState(false);

    function GetDisplayDate(props) {
        console.log("  @@ ReceiptsList - GetDisplayDate props.prop", props.prop);
        let retDate = new Date(props.prop).toLocaleDateString();
        if (props !=="") {
          return <div>{retDate}</div>
        }
      }

    useEffect(() => {
        const loadReceipts = async () => {
            console.log("ReceiptsList - useEffect")
            try {
                const response = await axios.get(`https://${baseUrl}/api/receipts/${user.id}`);
                console.log(" ReceiptsList - response: ",response);
                const responseData  = response.data.message;
                console.log(" ReceiptsList - responseData: ",responseData);
                if (responseData === "No data found.") setIsSkipp(true);
                setReceipts(responseData);
                setIsSuccess(true);
                setIsLoading(false);
            } catch (e) { 
                /*
                // not really catching anything.....
                //https://stackoverflow.com/questions/47005457/handling-axios-error-in-react
                if (e.response) {
                    console.log( " ReceiptsList - caught error: ", e.response.status)
                } else if (e.request) {
                    console.log( " ReceiptsList - caught error: ", e.response.request)
                } else {
                    console.log( " ReceiptsList - caught error: ", e.message)
                }
                */
                
                setIsSuccess(false);
                setIsLoading(false);
            }
        }

        loadReceipts();
    }, [user]);

    if (isLoading) return <p>Loading...</p>;
    if (isSkipp) return <p>No prior transactions found</p>
    if (isSuccess) { 
        
                console.log(" ReceiptsList - receipts: "+JSON.stringify(receipts));
        
        return (
        <>
            {receipts.map((receipt, key) => (
                <p className={classAct} key={key}><GetDisplayDate prop={receipt.data.createdAt}/>#&nbsp;<a href={receipt.data.receiptUrl} target="_blank" rel="noreferrer">{receipt.data.receiptNumber}</a></p>
            ))}
        </>
    ) } else {
        return (
            <p className='text-danger bg-warning'>...Network error? using Proxy/VPN?</p>
        )
    }
}
export default ReceiptsList;
/*
    {token.map((receipt, key) => (
        <Link className="receipt-list-item" key={key} to={`/receipt/${receipt.data.referenceId}`}>
            <h3>{receipt.data.referenceId}</h3>
            <p>{receipt.receiptUrl[0].substring(0, 150)}...</p>
        </Link>
    ))}
*/