import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useToken } from '../auth/useToken';
//import { useQueryParams } from '../util/useQueryParams';
import { xlateErrorMessage } from '../util/xlateErrorMessage';

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

export const LogInPage = () => {
    const [, setToken] = useToken();

    const [errorMessage, setErrorMessage] = useState('');

    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');

    //const [googleOauthUrl, setGoogleOauthUrl] = useState('');
    //const { token: oauthToken } = useQueryParams();

    const history = useHistory();

    /*
    useEffect(() => {
        if (oauthToken) {
            setToken(oauthToken);
            history.push('/main');
            history.go();
        }
    }, [oauthToken, setToken, history]);

    useEffect(() => {
        const loadOauthUrl = async () => {
            try {
                const response = await axios.get(`https://${baseUrl}/auth/google/url`);
                const { url } = response.data;
                console.log("LoginPage - response: "+JSON.stringify(response));
                console.log("LoginPage - response.data: "+JSON.stringify(response.data));
                setGoogleOauthUrl(url);
            } catch (e) {
                console.log("error while trying to get GoogleURL: "+e);
                return 0;
            }
        }

        loadOauthUrl();
    }, []);
    */

    const onLogInClicked = async (event) => {
        event.preventDefault(); // without this, it would re-draw and won't be able to see error displays
        try {
            const response = await axios.post(`https://${baseUrl}/api/login`, {
                email: emailValue.toLowerCase(),
                password: passwordValue,
            });
            console.log("@@ LoginPage - response: "+JSON.stringify(response));
            console.log("@@ LoginPage - response.data: "+JSON.stringify(response.data));
            console.log("@@ LoginPage - response.message (this will show success or not for login- should be undefined if not error): "+response.data.message);

            if (response.data.message !== 'NG') {
                console.log("processing login....");
                const { token } = response.data;
                setToken(token);
                history.push('/main');
                history.go();
            } else {
                setErrorMessage("Not Authorized. Check your credentials. Do you have an account with us?");
            }
        } catch(e) {
            //setErrorMessage(e.message);
            console.log("@ @ @ @ @ @ @ caught error: ", e);
            setErrorMessage(xlateErrorMessage(e));
        }
    }

    return (
        <div className='container lh-sm fw-light px-5 mt-4 mb-5' style={{maxWidth: "800px"}}>
            <h1>Login</h1>
            <div className='row py-2'>
                <div className='col-12'>
                    <div className="card bg-dark">
                        <div className="card-body">
                            {errorMessage && <div className="text-danger bg-warning p-2 m-2">{errorMessage}</div>}
                            <form>
                            <input
                                value={emailValue}
                                onChange={e => setEmailValue(e.target.value)}
                                placeholder="someone@gmail.com" />
                            <input
                                type="password"
                                value={passwordValue}
                                autoComplete='on'
                                onChange={e => setPasswordValue(e.target.value)}
                                placeholder="password" />
                            <hr />
                            <div className="container-sm">
                                <div className="d-grid gap-1">
                                    <button
                                        className="btn btn-primary"
                                        disabled={!emailValue || !passwordValue}
                                        onClick={onLogInClicked}>Log In</button>
                                    <button type="button" className='btn btn-secondary' onClick={() => history.push('/forgot-password')}>Forgot your password?</button>
                                </div>
                            </div>
                            </form>
                        </div> {/* card body */}
                    </div>
                </div>{/* col */}
            </div>{/* row */}
            <div className='row py-2'>
                <div className='col-12'>
                    <div className='card bg-dark'>
                        <div className='card-header'>
                            Don't have an account?
                        </div>
                        <div className='card-body'>
                            <div className='container-sm'>
                                <div className='d-grid gap-1'>
                                    <button type="button" className='btn btn-outline-secondary' onClick={() => history.push('/signup')}>Sign Up</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
/*
                                <button
                                    disabled={!googleOauthUrl}
                                    onClick={() => { window.location.href = googleOauthUrl }}
                                >Log in with Google</button>
*/
