import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
    //const currentYear=new Date().toLocaleString('en-US',{ timeZone: 'America/New_York' });
    const currentYear=new Date().getFullYear();
    return (
        <>
        <footer className="footer mt-2">
            <nav className="navbar navbar-expand-sm bg-dark navbar-light">
                <div className='container'>
                    <ul className="navbar-nav">
                        <li className='nav-item'>
                            <a className="nav-link fs-6 text-secondary" aria-current="page" href="http://www.fencingleagueofamerica.org">&copy; 2021-{currentYear} Fencing League of America</a>
                        </li>
                        <li className='nav-item'><a className="nav-link fs-6 text-secondary" aria-current="page" href="/about">About</a></li>
                        <li className='nav-item'><a className="nav-link fs-6 text-secondary" aria-current="page" href="/terms">T&amp;C</a></li>
                        <li className='nav-item'><a className="nav-link fs-6 text-secondary" aria-current="page" href="https://fla.ladesk.com" target="_blank" rel="noreferrer">Contact</a></li>
                    </ul>
                </div>
            </nav>
        </footer>
</>
    );
}
export default Footer;