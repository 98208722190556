import React from 'react';

export const TournamentPolicy = () => {
    return (
        <>
            <p><span className='fw-bold'>Policy Update: </span>Fencing League of America (FLA) in our endeavor to keep fencing a respectable sport, is enforcing a zero tolerance for poor behavior from fencers/coaches/spectators. Any disruptive, poor, and/or unsportsmanlike behavior will result in black cards issued and removal for the premises. We thank you in advance for your cooperation in keeping this an enjoyable and awesome sport for everybody.</p>

            <p><span className='fw-bold'>** Liability waiver - </span>Upon entering this tournament you agree to abide by the current rules and code of conduct instituted by FLA. You acknowledge you are entering this tournament at your own risk and release AAU/FLA and its sponsors, SMC, referees, and staff from any liability. With entry you give consent to FLA to obtain medical care from any licensed physician, hospital or clinic for any injury or illness that may arise during activities associated with this tournament. **</p>

            <p><span className='fw-bold'>*** PHOTO/VIDEO RELEASE - </span>By registering in this tournament you agree and hereby consent to the recording of statements, photographs, and/or audio or video recordings taken of the Minor or you by FENCING LEAGUE OF AMERICA or its contractors. All statements, photographs, and/or audio or video recordings taken of the Minor or you by FENCING LEAGUE OF AMERICA or its contractors, shall be the sole property of FENCING LEAGUE OF AMERICA. Neither the Minor nor you shall receive any compensation in connection with use of these statements, photographs, and/or audio or video recordings for promotional, commercial or other purposes. On behalf of the Minor and yourself, you hereby release, waive and discharge any claims of any kind or nature arising out of or relating to the use of the statements, photographs, and/or audio or video recordings against FENCING LEAGUE OF AMERICA or any person or firm authorized by FENCING LEAGUE OF AMERICA to publish said materials (&quot;Publisher&quot;), Such release, waiver and discharge shall also extend to all affiliated companies, shareholders, directors, officers, employees, agents and assigns of FENCING LEAGUE OF AMERICA and any Publisher. This Release shall be binding upon the Minor and yourself, and any respective successors, heirs, assigns, executors, administrators, spouse and next of kin.
            *Event will be LIVE-STREAMED so everyone participating automatically accepts waiver to be recorded.</p>
    </>
    )
}