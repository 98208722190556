import { useHistory } from 'react-router-dom';

export const EmailVerificationSuccess = () => {
    const history = useHistory();

    return (
        <div className='container-fluid lh-sm fw-light px-5 mt-4'>
            <h1>Email Verification was Success!</h1>
            <div className='row'>
                <div className='col-12'>
                    <p>Thanks for verifying your email, now you can use all the app's features.</p>
                    <p></p>
                    <p>Please log out once if you are still logged in.</p>
                </div>
            </div>
            <button onClick={() => history.push('/logout')}>Logout</button>
        </div>
    );
}
