import { useHistory } from 'react-router-dom';

export const TermsPage2 = () => {
    const history = useHistory();

    return (
        <div className='container-fluid lh-sm fw-light px-5 mt-4'>
            <h1>Fencing League of America Website</h1>
            <div className='row'>
                <div className='col-12'>

  <h2>Terms and Conditions</h2>
  <p>In using this website you are deemed to have read and
    agreed to the following terms and conditions:</p>

  <p>The following terminology applies to&nbsp;these Terms and Conditions, Privacy Statement and
    any disclaimer Notice and any or all Agreements:&nbsp;&quot;Client&quot;, “You”
    and “Your” refers to you, the person accessing this website and accepting the
    Company’s terms and conditions. &quot;The Company&quot;, “Ourselves”, “We” and
    &quot;Us&quot;, refers to our Company. “Party”, “Parties”, or “Us”, refers to
    both the Client and ourselves, or either the Client or ourselves. All terms
    refer to the offer, acceptance and consideration of payment necessary to
    undertake the process of our assistance to the Client in the&nbsp;most
    appropriate manner, whether by formal meetings of a fixed duration, or any
    other means, for the express purpose of meeting the Client's needs in respect
    of provision of the Company's stated services/products, in accordance with and
    subject to, prevailing United States Law. Any use of the above terminology or other
    words in the singular, plural, capitalisation and/or&nbsp;he/she or they, are
    taken as interchangeable and therefore as referring to same.</p>

  <h3>Privacy Statement</h3>
    <p>We
    are committed to protecting your privacy. Authorised employees within the
    company on a need to know basis only use any information collected from
    individual Clients. We constantly review our systems and data to ensure the
    best possible service to our Clients. There are specific offences for
    unauthorised actions against computer systems and data. We will investigate any
    such actions with a view to prosecuting and/or taking civil proceedings to
    recover damages against those responsible</p>

  <h3>Confidentiality</h3>
  <p>Any information concerning the Client and their respective Client Records may be
    passed to third parties. However, Client records are regarded as confidential
    and therefore will not be divulged to any third party, other than our employees
    and if legally required to do so to the appropriate authorities. Clients have
    the right to request sight of, and copies of any and all Client Records we
    keep, on the proviso that we are given reasonable notice of such a request.
    Clients are requested to retain copies of any literature issued in relation to
    the provision of our services. Where appropriate, we shall issue Client's with
    appropriate written information, handouts or copies of records as part of an
    agreed contract, for the benefit of both parties.</p>

  <p>We will not sell, share, or rent your personal information to any third party or
    use your e-mail address for unsolicited mail. Any emails sent by this Company
    will only be in connection with the provision of agreed services and products.</p>

  <h4>Disclaimer</h4>
  <h3>Exclusions and Limitations</h3>
  <p>The information on this web site is provided on an &quot;as is&quot; basis. To
    the fullest extent permitted by law, this Company:</p>

  <ul>
    <li>excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company’s literature; and</li>
    <li>excludes all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.</li>
  </ul>

  <p>This Company does not however exclude liability for death or personal injury caused
    by its negligence. The above exclusions and limitations apply only to the
    extent permitted by law. None of your statutory rights as a consumer are
    affected.</p>

  <h3>Availability</h3>
    <p>Unless
    otherwise stated, the services featured on this website are only available
    within the United States, or in relation to postings from the United States.
    All advertising is intended solely for the United States market. You are
    solely responsible for evaluating the fitness for a particular purpose of any
    downloads, programs and text available through this site. Redistribution or
    republication of any part of this site or its content is prohibited, including
    such by framing or other similar or any other means, without the express
    written consent of the Company. The Company does not warrant that the service
    from this site will be uninterrupted, timely or error free, although it is
    provided to the best ability. By using this service you thereby indemnify this
    Company, its employees, agents and affiliates against any loss or damage, in
    whatever manner, howsoever caused.</p>

  <h3>Log Files</h3>
    <p>We
    use IP addresses to analyse trends, administer the site, track user's movement,
    and gather broad demographic information for aggregate use. IP addresses are
    not linked to personally identifiable information. Additionally, for systems
    administration, detecting usage patterns and troubleshooting purposes, our web
    servers automatically log standard access information including browser type,
    access times/open mail, URL requested, and referral URL. This information is
    not shared with third parties and is used only within this Company on a
    need-to-know basis. Any individually identifiable information related to this
    data will never be used in any way different to that stated above without your
    explicit permission.</p>

  <h3>Cookies</h3>
    <p>Like
    most interactive web sites this Company's website uses cookies to enable us to
    retrieve user details for each visit. Cookies are used in some areas of our
    site to enable the functionality of this area and ease of use for those people
    visiting. Some of our affiliate partners may also use cookies.</p>

  <h3>Links to this website</h3>
    <p>You may not create a link to any page of this website without our prior written
    consent. If you do create a link to a page of this website you do so at your
    own risk and the exclusions and limitations set out above will apply to your
    use of this website by linking to it.</p>

  <h3>Links from this website</h3>
    <p>We do not monitor or review the content of other party's websites which are
    linked to from this website. Opinions expressed or material appearing on such
    websites are not necessarily shared or endorsed by us and should not be
    regarded as the publisher of such opinions or material. Please be aware that we
    are not responsible for the privacy practices, or content, of these sites. We
    encourage our users to be aware when they leave our site &amp; to read the
    privacy statements of these sites. You should evaluate the security and
    trustworthiness of any other site connected to this site or accessed through
    this site yourself, before disclosing any personal information to them. This
    Company will not accept any responsibility for any loss or damage in whatever
    manner, howsoever caused, resulting from your disclosure to third parties of
    personal information.</p>

  <h3>Copyright and Trademark</h3>
    <p>Copyright
    and other relevant intellectual property rights exists on all text relating to
    the Company's services and the full content of this website.</p>
  <h3>Communication</h3>
    <p>We
    have several different e-mail addresses for different queries. These, &amp;     other contact information, can be found on our Contact Us link on our
    website or via Company literature or via the Company’s stated telephone,
    facsimile or mobile telephone numbers.</p>

  <p>This company is in the United States of America, office 425 S Avalon Park Blvd, Orlando, FL 32828.</p>

  <h2>Force Majeure</h2>
  <p>Neither party shall be
    liable to the other for any failure to perform any obligation under any
    Agreement which is due to an event beyond the control of such party including
    but not limited to any Act of God, terrorism, war, Political insurgence,
    insurrection, riot, civil unrest, act of civil or military authority, uprising,
    earthquake, flood or any other natural or man made eventuality outside of our
    control, which causes the termination of an agreement or contract entered into,
    nor which could have been reasonably foreseen. Any Party affected by such event
    shall forthwith inform the other Party of the same and shall use all reasonable
    endeavours to comply with the terms and conditions of any Agreement contained
    herein.</p>

  <h3>Waiver</h3>
  <p>Failure of either Party
    to insist upon strict performance of any provision of this or any Agreement or
    the failure of either Party to exercise any right or remedy to which it, he or
    they are entitled hereunder shall not constitute a waiver thereof and shall not
    cause a diminution of the obligations under this or any Agreement. No waiver of
    any of the provisions of this or any Agreement shall be effective unless it is
    expressly stated to be such and signed by both Parties.</p>

  <h3>General</h3>
  <p>The laws of England and Wales govern these terms and conditions. By accessing this
    website and using our services/buying our products, you consent to these terms
    and conditions and to the exclusive jurisdiction of the United States courts in all
    disputes arising out of such access. If any of these terms are deemed invalid
    or unenforceable for any reason (including, but not limited to the exclusions
    and limitations set out above), then the invalid or unenforceable provision
    will be severed from these terms and the remaining terms will continue to
    apply. Failure of the Company to enforce any of the provisions set out in these
    Terms and Conditions and any Agreement, or failure to exercise any option to
    terminate, shall not be construed as waiver of such provisions and shall not
    affect the validity of these Terms and Conditions or of any Agreement or any
    part thereof, or the right thereafter to enforce each and every provision.
    These Terms and Conditions shall not be amended, modified, varied or
    supplemented except in writing and signed by duly authorised representatives of
    the Company.</p>

  <h3>Notification of Changes</h3>
  <p>The Company reserves the right to change these conditions from time to time as it
    sees fit and your continued use of the site will signify your acceptance of any
    adjustment to these terms. If there are any changes to our privacy policy, we
    will announce that these changes have been made on our home page and on other
    key pages on our site. If there are any changes in how we use our site Clients’
    Personally Identifiable Information, notification by e-mail or postal mail will
    be made to those affected by this change. Any changes to our privacy policy
    will be posted on our web site 30 days prior to these changes taking place. You
    are therefore advised to re-read this statement on a regular basis</p>
  <blockquote className='text-end text-secondary'>&copy; Fencing League of America / Sword Masters Club 2022 All Rights Reserved</blockquote>

                </div>
            </div>
        </div>
    );
}