import { useState, useEffect } from 'react';
import { useToken } from './useToken';

export const useUser = () => {
    const [token] = useToken();
    //console.log(">>@@ useUser - token:"+JSON.stringify(token));

    const getPayloadFromToken = token => {
        const encodedPayload = token.split('.')[1]; // core data
        const decodedPayload = Buffer.from(encodedPayload,'base64');
        //return JSON.parse(atob(encodedPayload));
        let now = (new Date());
        const {exp} = JSON.parse(Buffer.from(decodedPayload));
        console.log("------ useUser.js - decodedPayload: "+decodedPayload);
        console.log("------ useUser.js - decodedPayload.id: "+decodedPayload.id);
        console.log("------ useUser.js - now.getTime(): "+now.getTime());
        console.log("------ useUser.js - exp: "+exp);
        if (exp < (now / 1000)) {
            console.log("------- useUser.js - expired!");
            //return null;
            return null;
        } else {
            console.log("------- useUser.js - NOT expired!");
        }
        console.log("------ useUser.js - exp: "+exp);
        return JSON.parse(Buffer.from(encodedPayload,'base64'));
    }

    const [user, setUser] = useState(() => {
        if (!token) return null;
        return getPayloadFromToken(token);
    });

    useEffect(() => {
        if (!token) {
            setUser(null);
        } else {
            setUser(getPayloadFromToken(token));
        }
    }, [token]);

    return user;
}