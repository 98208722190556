import { useHistory } from 'react-router-dom';

export const EmailVerificationFail = () => {
    const history = useHistory();

    return (
        <div className='container-fluid lh-sm fw-light px-5 mt-4'>
            <h1>Uh oh...</h1>
            <div className='row'>
                <div className='col-12'>
            <p>
                Something went wrong while trying to verify your email.
            </p>
                </div>
            </div>
            <button onClick={() => history.push('/signup')}>Back to Sign-up</button>
        </div>
    );
}