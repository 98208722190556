import { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import axios from 'axios';
import { useToken } from '../auth/useToken';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import validator from 'validator';
import { useUser } from '../auth/useUser';

import "./css/SignUpPage.css";

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

export const SignUpPage = () => {
    const user=useUser();
    const [token, setToken] = useToken();

    const [errorMessage, setErrorMessage] = useState('');

    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');

    const [firstValue,setFirstValue] = useState('');
    const [middleValue,setMiddleValue] = useState('');
    const [lastValue,setLastValue] = useState('');
//    const [favoriteFoodValue,setFavoriteFoodValue] = useState('');
//    const [hairColorValue,setHairColorValue] = useState('');
//    const [bioValue,setBioValue] = useState('');

    const [dobValue, setDobValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [streetAddress1Value, setStreetAddress1Value] = useState('');
    const [streetAddress2Value, setStreetAddress2Value] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [stateValue, setStateValue] = useState('');
    const [postalCodeValue, setPostalCodeValue] = useState('');
    const [countryValue, setCountryValue] = useState('');
    const [contactNameValue, setContactNameValue] = useState('');
    const [contactKindValue, setContactKindValue] = useState('');
    const [contactNumberValue, setContactNumberValue] = useState('');
    const [dominantHandValue, setDominantHandValue] = useState('');
    const [schoolValue, setSchoolValue] = useState('');
    const [genderValue, setGenderValue] = useState('');
    const [aauValue, setAauValue] = useState('');

    const [emailError, setEmailError] = useState('');

    const [dobError, setDobError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [contactNumberError, setContactNumberError] = useState('');
    const [passwordStrengthError, setPasswordStrengthError] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');


    // https://github.com/validatorjs/validator.js
    const validateDob = (e) => {
        var dob = e.target.value;
        //if (!validator.isDate(dob,'MM/DD/YYYY','/')){
        if (!validator.isDate(dob)){
            //setDobError('Enter Date of Birth in the format of MM/DD/YYY');
            setDobError('Enter proper date of birth');
        } else { setDobError('')}
    }

 //   const [country, setCountry] = useState('');
//    const [region, setRegion] = useState('');

//    const [countryValue, setCountryValue] = useState('');
    const [regionValue, setRegionValue] = useState('');


    const selectCountry = (val) => {
        console.log("selectCountry - val: "+val);
  //      setCCountry( val );
        //onChangeProfile( {country: cCountry} ); 
        //onChangeProfile( {country: val} ); 
        setCountryValue( val );
        console.log("selectCountry - country: "+countryValue);
    }

    const selectRegion = (val) => {
        console.log("selectRegion - val: "+val);
        //setRegion( val );
        //onChangeProfile( {state: region} ); 
        //onChangeProfile( {state: val} ); 
        //setRegionValue( val );
        setStateValue( val );
        console.log("selectRegion - state: "+stateValue);
    }


    const history = useHistory();

    // had to call it here because of "useXXXX" restrictions
    if(user) return <Redirect to="/main" />

    const validateEmail = (e) => {
        var email = e.target.value;
        if (!validator.isEmail(email)){
            setEmailError('Enter Valid Email');
        } else { setEmailError('')}
    }

    const validatePasswordStrength = (e) => {
        const pwdLength = Number(e.target.value.length);
        const minLen=Number(process.env.REACT_APP_MIN_PASSWORD_LENGTH);
        if (minLen > pwdLength){
            setPasswordStrengthError(`minimum password length is ${minLen}`);
        } else {
            setPasswordStrengthError('');
        }
    }

    const onSignUpClicked = async () => {
        try{
            const response = await axios.post(`https://${baseUrl}/api/signup`, {
                email: emailValue.toLowerCase(),
                password: passwordValue,
                first: firstValue,
                middle: middleValue,
                last: lastValue,
//                favoriteFood: favoriteFoodValue,
//                hairColor: hairColorValue,
//                bio: bioValue,
                dob: dobValue,
                phone: phoneValue,
                streetAddress1: streetAddress1Value,
                streetAddress2: streetAddress2Value,
                city: cityValue,
                state: stateValue,
                postalCode: postalCodeValue,
                country: countryValue,
                contactName: contactNameValue,
                contactKind: contactKindValue,
                contactNumber: contactNumberValue,
                dominantHand: dominantHandValue,
                school: schoolValue,
                gender: genderValue,
                aauNumber: aauValue,
            });
            const { token } = response.data;
            setToken(token);
            history.push('/please-verify');
            history.go();
        } catch (e) {
            setErrorMessage(e.message);
        }
    }

    if (errorMessage && errorMessage==="Request failed with status code 409") {
        setErrorMessage("Error - Do you already have an account with us?");
    }

    return (

        <div className='container lh-sm fw-light px-5 mt-4'>
            <h1>Sign Up</h1>
            <p className='text-warning p-2'>Incomplete/inaccurate information on your profile may lead to delays, disqualifications and/or unnacceptance of your event registrations and results.</p>
            <div className='row'>
                <div className='col-12'>

                    <div className="text-dark content-container position-relative bg-light rounded-2 px-2 py-2">
                        {errorMessage && <div className="text-danger bg-warning p-2 my-3">{errorMessage}</div>}
                        <input
                            required
                            type="email"
                            value={emailValue}
                            onChange={e => {
                                setEmailValue(e.target.value);
                                validateEmail(e);
                            }}
                            placeholder="someone@gmail.com" />
                        {emailError && <div className="text-danger">{emailError}</div>}
                        <input
                            type="password"
                            value={passwordValue}
                            onChange={e => {setPasswordValue(e.target.value); validatePasswordStrength(e)}}
                            placeholder="password" />
                        <input
                            type="password"
                            value={confirmPasswordValue}
                            onChange={e => {setConfirmPasswordValue(e.target.value);}}
                            placeholder="password" />
                            {passwordStrengthError && <div className='text-danger'>{passwordStrengthError}</div>}
                            {passwordMatchError && <div className='text-danger'>{passwordMatchError}</div>}
                        <p className='text-info bg-dark p-2 m-2'>Plese make sure your email address is correct.</p>
                        <hr />
                        <p><span className="text-danger">*</span> denotes required field.</p>
                        <hr />
                        <label>
                            First Name<span className="text-danger">*</span>:{" "}
                            <input
                            required
                                onChange={e => setFirstValue(e.target.value)}
                                value={firstValue} maxLength="80" />
                        </label>
                        <label>
                            Middle:{" "}
                            <input
                                onChange={e => setMiddleValue(e.target.value)}
                                value={middleValue}
                                size="6" maxLength="5"
                            />
                        </label>
                        <label>
                            Last Name<span className="text-danger">*</span>:{" "}
                            <input
                            required
                                onChange={e => setLastValue(e.target.value)}
                                value={lastValue} maxLength="80" />
                        </label>


            <label>Date of Birth<span className="text-danger">*</span>:<input type="date" onChange={e => {setDobValue(e.target.value);
                            validateDob(e)
                            }} value={dobValue} required/>
                        <span className="text-danger">{dobError}</span></label>
            <label>Gender<span className="text-danger">*</span>: <select name="gender" value={genderValue}
                            onChange={e=> setGenderValue(e.target.value)} required >
                            <option value="" >Choose...</option>
                            <option value="Male" >Male</option>
                            <option value="Female" >Female</option>
                            </select>
                        </label>

                        <hr />
            <label>Phone<span className='text-danger'>*</span>:{" "}<input onChange={e => setPhoneValue(e.target.value)} value={phoneValue} placeholder="000-000-0000" required /></label>
            <label>Street Address 1<span className='text-danger'>*</span>:{" "}<input onChange={e => setStreetAddress1Value(e.target.value)} value={streetAddress1Value} required /></label>
            <label>Street Address 2:{" "}<input onChange={e => setStreetAddress2Value(e.target.value)} value={streetAddress2Value}/></label>
            <label>City<span className='text-danger'>*</span>:{" "}<input onChange={e => setCityValue(e.target.value)} value={cityValue} required /></label>
                        <label>Country<span className='text-danger'>*</span>:<CountryDropdown
                                value={countryValue}
                                onChange={(val) => selectCountry(val)}
                                valueType="short"
                            /></label>
                            {" "}
                            <label>State/Region<span className='text-danger'>*</span>:{" "}
                            <RegionDropdown
                                country={countryValue}
                                value={stateValue}
                                onChange={(val) => selectRegion(val)}
                                countryValueType="short"
                            /></label>


            <label>Postal Code<span className='text-danger'>*</span>:{" "}<input onChange={e => setPostalCodeValue(e.target.value)} value={postalCodeValue} required /></label>
            <style display="none">
                <label>state:{" "}<input onChange={e => setStateValue(e.target.value)} value={stateValue} required /></label>
                <label>country:{" "}<input onChange={e => setCountryValue(e.target.value)} value={countryValue} required /></label>
            </style>
            <hr />
            <label>Dominant Hand: <select name="dominantHand" value={dominantHandValue}
                            onChange={e=> setDominantHandValue(e.target.value)} >
                            <option value="" >Choose...</option>
                            <option value="right" >Right</option>
                            <option value="left">Left</option>
                        </select></label>
            <label>School:{" "}<input onChange={e => setSchoolValue(e.target.value)} value={schoolValue}/></label>
            <label>AAU#:{" "}<input onChange={e => setAauValue(e.target.value)} value={aauValue}/></label>

                        <hr />
            <label>Emergency Contact Name<span className='text-danger'>*</span>:{" "}<input onChange={e => setContactNameValue(e.target.value)} value={contactNameValue} required /></label>
            <label>Emergency Contact Kind<span className='text-danger'>*</span>:{" "}<input onChange={e => setContactKindValue(e.target.value)} value={contactKindValue} placeholder="Father, Mother, etc." required /></label>
            <label>Emergency Contact Number<span className='text-danger'>*</span>:{" "}<input onChange={e => setContactNumberValue(e.target.value)} value={contactNumberValue} placeholder="000-000-0000" required /></label>


                        <hr/>
                        {errorMessage && <div className="text-danger bg-warning p-2 my-3">{errorMessage}</div>}
                        <button
                            disabled={
                                emailError || !firstValue || !lastValue ||!emailValue || !passwordValue ||
                                !phoneValue || !dobValue || !countryValue || !stateValue || !streetAddress1Value ||
                                !cityValue ||
                                !postalCodeValue ||
                                !contactNameValue ||
                                !contactKindValue ||
                                !contactNumberValue || !genderValue ||
                                passwordStrengthError ||
                                passwordValue !== confirmPasswordValue
                            }
                            onClick={onSignUpClicked}>Sign Up</button>
                        <button onClick={() => history.push('/login')}>Already have an account? Log In</button>
                    </div>

                </div>

            </div>
            <button onClick={() => history.push('/login')}>Log in</button>
        </div>
    );
}
//<label>Dominant Hand:{" "}<input onChange={e => setDominantHandValue(e.target.value)} value={dominantHandValue}/></label>
//<label>Gender:{" "}<input onChange={e => setGenderValue(e.target.value)} value={genderValue}/></label>
//<label>Date of Birth:{" "}<input onChange={e => setDobValue(e.target.value)} value={dobValue}/></label>