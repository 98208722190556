import React from 'react';
import Navigation from './pages/Navigation';
import Footer from './pages/Footer';
import { Routes } from './Routes';

import { useUser } from './auth/useUser';
import './App.css';

export const App = () => {

    console.log(process.env.REACT_APP_NODE_ENV)
    if(process.env.REACT_APP_NODE_ENV === 'production' && process.env.REACT_APP_DEBUG !== 'true') {
        console.log = function() {};
    };

var loggedIn = false

const user = useUser();
//console.log("@@@ in App: user #1: "+JSON.stringify(user));
//console.log("@@@ in App: user.id: "+JSON.stringify(user.id));

if(user){
    loggedIn = true;
} else {
    loggedIn = false;
}
console.log("@@@ in App: loggedIn #2: "+loggedIn);
            //<Routes userId={user.id}/>
    return (
        <>
            <Navigation myprop={loggedIn}/>
            <div className="main-container pt-5 mt-3 mx-0">
                <Routes />
                <Footer/>
            </div>
        </>
    );
}
/* /// outer container before Nav - replaced with empty <></> tag
        <div className="page-container mt-0 mx-0 bg-dark"></div>
        </div>
*/
