import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import browserInfo from '@smartbear/browser-info';
import { useUser } from '../auth/useUser';
import { addPayment } from '../util/addPayment';

const { nanoid } = require('nanoid');

// https://developer.squareup.com/docs/web-payments/take-card-payment
// https://codesandbox.io/s/square-web-payments-react-nhgxy?file=/src/App.js

browserInfo.detect();

const APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
const LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID;
const isSafari = browserInfo.name === "Safari";
const paymentRequestMock = {
  countryCode: "US",
  currencyCode: "USD",
  lineItems: [
    { amount: "1.23", label: "Cat", pending: false },
    { amount: "4.56", label: "Dog", pending: false }
  ],
  requestBillingContact: true,
  requestShippingContact: false,
  shippingContact: {
    addressLines: ["1 Test St", ""],
    city: "San Francisco",
    countryCode: "US",
    email: "test@squareup.com",
    familyName: "First Name",
    givenName: "Last Name",
    phone: "+12345678910",
    postalCode: "11111",
    state: "CA"
  },
  shippingOptions: [
    { amount: "0.00", id: "FREE", label: "Free" },
    { amount: "9.99", id: "XP", label: "Express" }
  ],
  total: { amount: "5.79", label: "Total", pending: false }
};

// This function tokenizes a payment method.
// The ‘error’ thrown from this async function denotes a failed tokenization,
// which is due to buyer error (such as an expired card).
export async function tokenizePaymentMethod(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  // A list of token statuses can be found here:
  // https://developer.squareup.com/reference/sdks/web/payments/enums/TokenStatus
  if (tokenResult.status === "OK") {
    return tokenResult.token;
  }
  let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
  if (tokenResult.errors) {
    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
  }
  throw new Error(errorMessage);
}

///////////////////////////
///////////////////////////

export const CardPaymentPage = () => {
  const history = useHistory();
  const user = useUser;
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  console.log("CardPaymentPage - user: "+JSON.stringify(user));

  const [payment, setPayment] = useState();

  const [loaded, setLoaded] = useState(false);
  const [squarePayments, setSquarePayments] = useState(undefined);
  const [squareCard, setSquareCard] = useState(undefined);
  const [applePay, setApplePay] = useState(undefined);
  const [googlePay, setGooglePay] = useState(undefined);
  const [isSubmitting, setSubmitting] = useState(false);
  const [validFields, setValidFields] = useState({
    cardNumber: false,
    cvv: false,
    expirationDate: false,
    postalCode: false
  });
  const isCardFieldsValid = Object.values(validFields).every((v) => v);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // This useEffect hook automatically hides the
  // success and error messages after 3 seconds when they're shown.
  useEffect(() => {
      if (showSuccessMessage || showErrorMessage) {
          setTimeout(() => {
              setShowSuccessMessage(false);
              setShowErrorMessage(false);
              //history.go();
          }, 10000);
      }
  }, [showSuccessMessage, showErrorMessage]);


  // Add Square script to the page
  useEffect(() => {
    const existingScript = document.getElementById("webPayment");
    if (existingScript) setLoaded(true);
    else {
      const script = document.createElement("script");
      script.src = "https://sandbox.web.squarecdn.com/v1/square.js";
      script.id = "webPayment";
      document.body.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, []);

  // Instantiate Square payments and store the object in state
  useEffect(() => {
    if (loaded && !squarePayments) {
      if (!window?.Square) {
        console.error("Square.js failed to load properly");
        return;
      }
      setSquarePayments(window.Square?.payments(APPLICATION_ID, LOCATION_ID));
      console.log("CardPaymentPage: squarePayments: "+JSON.stringify(squarePayments));
    }
  }, [loaded, squarePayments]);

  // Handle the form submission
  const handlePaymentMethodSubmission = async (paymentMethod) => {
    const isCard = paymentMethod?.element?.id === "card-container";
    if (isCard && !isCardFieldsValid) return;
    if (!isSubmitting) {
      // Disable the submit button as we await tokenization and make a
      // payment request
      if (isCard) setSubmitting(true);
      try {
        const token = await tokenizePaymentMethod(paymentMethod);
        // Create your own addPayment function to communicate with your API
        // await addPayment(token)
        console.log("@@ @@ CardPaymentPage - derived TOKEN:", token); // this works kokanish
        // https://developer.squareup.com/docs/web-payments/take-card-payment
        //const paymentResults = await createPayment(token);
    //    const paymentResults = await createPayment(token);
        let payload = token; //kokanish

        const idempotencyKey = payload.idempotencyKey || nanoid();
        const requestBody = {
          idempotencyKey,
          //locationId: payload.locationId,
          locationId: LOCATION_ID,
          //sourceId: payload.sourceId,
          sourceId: token,
          // While it's tempting to pass this data from the client
          // Doing so allows bad actor to modify these values
          // Instead, leverage Orders to create an order on the server
          // and pass the Order ID to createPayment rather than raw amounts
          // See Orders documentation: https://developer.squareup.com/docs/orders-api/what-it-does
          amountMoney: {
          // the expected amount is in cents, meaning this is $1.00.
          //amount: '100',
          amount: paymentRequestMock.total.amount,
          // If you are a non-US account, you must change the currency to match the country in which
          // you are accepting the payment.
          currency: 'USD', // hard coding
          },
        };
        console.log(" @@@ CardPaymentsPage5 - requestBody: "+JSON.stringify(requestBody));
        // if (payload.customerId) {
        //   payment.customerId = payload.customerId;
        // }
        // // VerificationDetails is part of Secure Card Authentication.
        // // This part of the payload is highly recommended (and required for some countries)
        // // for 'unauthenticated' payment methods like Cards.
        // if (payload.verificationToken) {
        //   payment.verificationToken = payload.verificationToken;
        // }

      //////////////////////////////////
      // This is the "square.paymentsApi.createPayment" / addPayment() portion mentioned above
      //////////////////////////////////
      const response = await addPayment(requestBody);
      console.log (" @@ CardPaymentPage - response: "+JSON.stringify(response));

      const { id, createdAt, updatedAt, approvedMoney,
        status, sourceType, cardDetails,
        locationId, orderId, receiptNumber, receiptUrl,
        applicationDetails} = response || {};
      // the "approvedMoney", "cardDetails", "applicationDetails" will be an object
      
      console.log(" @@ CardPaymentPage - deconst const id: "+id);
      console.log(" @@ CardPaymentPage - deconst const createdAt: "+createdAt);
      console.log(" @@ CardPaymentPage - deconst const updatedAt: "+updatedAt);
      console.log(" @@ CardPaymentPage - deconst const approvedMoney: "+approvedMoney);
      console.log(" @@ CardPaymentPage - deconst const status: "+status);
      console.log(" @@ CardPaymentPage - deconst const sourceType: "+sourceType);
      console.log(" @@ CardPaymentPage - deconst const cardDetails: "+cardDetails);
      console.log(" @@ CardPaymentPage - deconst const locationId: "+locationId);
      console.log(" @@ CardPaymentPage - deconst const orderId: "+orderId);
      console.log(" @@ CardPaymentPage - deconst const receiptNumber: "+receiptNumber);
      console.log(" @@ CardPaymentPage - deconst const receiptUrl: "+receiptUrl);
      console.log(" @@ CardPaymentPage - deconst const applicationDetails: "+applicationDetails);

      setIsSuccess(true);
      setIsLoading(false);
      setShowSuccessMessage(true);

    console.log(" @@@@ CardPaymentsPage5 - payment:"+payment);
    if (payment) { console.log(" @@@ CardPaymentsPage5 - payment(raw) after try/catch: "+payment);}

          console.log (" @@ CardPaymentPage - apiResult after payentsApi: "+JSON.stringify(payment));
      
          const result = JSON.stringify(payment, (key, value) => {
            return typeof value === "bigint" ? parseInt(value) : value;
          }, 4);
          console.log (" @@ CardPaymentPage - result2 after payentsApi: "+JSON.stringify(result));
      

      } catch (error) { // tokenize failed
        console.error("FAILURE", error);
        setShowErrorMessage(true);
      } finally {
        isCard && setSubmitting(false);
      }
    }
  };

  const handleBackClick= () => {
    //history.goBack();
    history.push("/main");
  }

  // Set each card field validity on various events
  const handleCardEvents = ({ detail }) => {
    if (detail) {
      const { currentState: { isCompletelyValid } = {}, field } = detail;
      if (field) {
        setValidFields((prevState) => ({
          ...prevState,
          [field]: isCompletelyValid
        }));
      }
    }
  };
/*
  const initializeApplePay = async () => {
    const paymentRequest = squarePayments.paymentRequest(paymentRequestMock);
    const aPay = await squarePayments.applePay(paymentRequest);
    setApplePay(aPay);
    // Note: Apple pay does not need to be "attached"
  };*/

  const attachGooglePay = (gPay) => {
    const googlePayObject = gPay || googlePay;
    googlePayObject.attach("#google-pay", {
      buttonColor: "white",
      buttonSizeMode: "fill",
      buttonType: "long"
    });
  };

  const initializeGooglePay = async () => {
    const paymentRequest = squarePayments.paymentRequest(paymentRequestMock);

    // We *MUST* return a PaymentRequestUpdate from shipping contact/option
    // event listeners below
    // https://developer.squareup.com/reference/sdks/web/payments/objects/PaymentRequestUpdate
    const paymentRequestUpdate = {
      // error: "There was an error of some kind",
      // shippingErrors: {
      //   addressLines: "Error with the Address Lines",
      //   city: "Error with the City",
      //   country: "Error with the Country",
      //   postalCode: "Error with the Postal Code",
      //   state: "Error with the state",
      // },
      lineItems: paymentRequestMock.lineItems,
      shippingOption: paymentRequestMock.shippingOptions,
      total: paymentRequestMock.total
    };

    // Listener for shipping address changes
    paymentRequest.addEventListener("shippingcontactchanged", (contact) => {
      console.log({ contact });

      return paymentRequestUpdate;
    });
    // Listener for shipping option changes
    paymentRequest.addEventListener("shippingoptionchanged", (option) => {
      console.log({ option });

      return paymentRequestUpdate;
    });

    const gPay = await squarePayments.googlePay(paymentRequest);
    setGooglePay(gPay);
    attachGooglePay(gPay);
  };

  // Attach the Square card to our container and setup event listeners
  const attachCard = (card) => {
    // We pass in the card object during initialization, but re-use it from
    // state for normal re-renders
    const cardObject = card || squareCard;
    cardObject.attach("#card-container");
    // Listeners: https://developer.squareup.com/reference/sdks/web/payments/objects/Card#Card.addEventListener
    cardObject.addEventListener("submit", () =>
      handlePaymentMethodSubmission(cardObject)
    );
    cardObject.addEventListener("focusClassAdded", handleCardEvents);
    cardObject.addEventListener("focusClassRemoved", handleCardEvents);
    cardObject.addEventListener("errorClassAdded", handleCardEvents);
    cardObject.addEventListener("errorClassRemoved", handleCardEvents);
    cardObject.addEventListener("cardBrandChanged", handleCardEvents);
    cardObject.addEventListener("postalCodeChanged", handleCardEvents);
  };

  const initializeSquareCard = async () => {
    const card = await squarePayments.card();
    setSquareCard(card);
    attachCard(card);
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    if (squarePayments) {
      if (!squareCard) initializeSquareCard();
      //if (!applePay && isSafari) initializeApplePay();
      if (!googlePay) initializeGooglePay();
      else attachGooglePay();
    }
    // Otherwise, we destroy the objects and reset state
    else {
      if (squareCard) {
        squareCard.destroy();
        setSquareCard(undefined);
      }
      //if (applePay) {
      //  applePay.destroy();
      //  setApplePay(undefined);
      //}
      if (googlePay) {
        googlePay.destroy();
        setGooglePay(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squarePayments]);

  // Some quick button styles
  let cardButtonStyles = {
    backgroundColor: "#ddd",
    color: "white",
    padding: 16,
    fontFamily: "sans-serif",
    fontSize: "1rem",
    marginBottom: 16,
    borderRadius: 8,
    borderWidth: 0
  };
  if (isCardFieldsValid) {
    cardButtonStyles = {
      ...cardButtonStyles,
      backgroundColor: "black"
    };
  }

  return (
      <div className='container-fluid lh-sm fw-light px-5 mt-4'>
          <h1>Test Page!</h1>
          <div className='row'>
            <div className='col-12'></div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2rem"
                }}
              >
                {/* Apple Pay will not work with the demo Square IDs used in this file,
                but it will if you have a valid square account with Apple Pay configured
                correctly. If so, change the IDs to your sandbox and try it out */}
                {isSafari && !isSuccess && (
                  <div
                    id="apple-pay"
                    onClick={() => handlePaymentMethodSubmission(applePay)}
                    style={{
                      backgroundColor: "white",
                      padding: 11,
                      borderColor: "#bbb",
                      borderWidth: 1,
                      boxShadow: "0px 2px 4px #00000033",
                      fontFamily: "sans-serif",
                      fontSize: "0.9rem",
                      marginBottom: 16,
                      borderRadius: 3
                    }}
                  >
                    <span>Buy with Apple Pay</span>
                  </div>
                )}
                <div style={{ marginBottom: 24 }}>
                  { !isSuccess && (
                    <div
                    id="google-pay"
                    onClick={() => handlePaymentMethodSubmission(googlePay)}
                  />
                  )}
                </div>
                {isSuccess && (<p>Success!!</p>)}
                {showSuccessMessage && <div className="success">Successfully processed payment!</div>}
                <form id="payment-form">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    {!isSuccess && (<div id="card-container"></div>)}
                    {!isSuccess && (<button
                      id="card-button"
                      type="button"
                      style={cardButtonStyles}
                      disabled={!isCardFieldsValid || isSubmitting}
                      onClick={() => handlePaymentMethodSubmission(squareCard)}
                    >
                      Pay {paymentRequestMock.total.amount}
                    </button>)}
                    <button onClick={handleBackClick}>Back</button>
                    
                  </div>
                </form>
                <div id="payment-status-container"></div>
              </div>
          </div>
    </div>
  );
}