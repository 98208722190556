import React from "react";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "./css/PaymentActivities.css";

import PaymentActivitiesTable from "./components/PaymentActivitiesTable";

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}

export const PaymentActivities = (props) => {
  console.log(" @ PaymentActivities - props: " + JSON.stringify(props));
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Transactions",
        columns: [
          {
            Header: "Date/Time",
            accessor: "data.createdAt",
            Cell: ({ value }) => {
              //
              let myDate = new Date(value).toLocaleDateString();
              let myTime = new Date(value).toLocaleTimeString();
              return (
                <>
                  {myDate} {myTime}
                </>
              );
            },
          },
          {
            Header: "Order ID",
            accessor: "data.orderId",
            Cell: ({ value }) => (
              <Link to={{ pathname: `/receipt/${value}` }} target="_blank">
                ${value}
              </Link>
            ),
          },
          {
            Header: "Receipt",
            accessor: "data.receiptUrl",
            Cell: ({ value }) => (
              <Link to={{ pathname: `${value}` }} target="_blank">
                Receipt
              </Link>
            ),
          },
          {
            Header: "Amount",
            accessor: "data.fullOrder.total_charges",
            Cell: (tableInfo) => {
              //console.log("tableInfo.data: ",tableInfo.data);
              //console.log("tableInfo.data index: ",tableInfo.data[tableInfo.row.index].data.fullOrder.currency);
              let myCurrency = tableInfo.data[tableInfo.row.index].data.fullOrder.currency;
              let myAmount = tableInfo.data[tableInfo.row.index].data.fullOrder.total_charges;
              return `${myCurrency} ${myAmount.toFixed(2)}`;
            },
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    (async () => {
      //const result = await axios("https://api.tvmaze.com/search/shows?q=snow");
      const result = await axios(`https://${baseUrl}/api/receipts/${props.userId}`);

      console.log("result: ", result);
      console.log("result.data: ", result.data);
      console.log("result.data.message: ", result.data.message);
      if (result.data.message!=="No data found."){
        setData(result.data.message);
      } else {
        setData(false);
      }
    })();
  }, []);

  return (
      <div className='container lh-sm fw-light px-5 mt-4'>
        <h1>Your Payment Activities</h1>
        <div className="row">
          <div className="col-12">
            {console.log(" @@ PaymentActivities - data: ", data)}
            {console.log(" @@ PaymentActivities - data.length: ", data.length)}
            {console.log(" @@ PaymentActivities - data.success: ", data.success)}
            {data?<PaymentActivitiesTable columns={columns} data={data} />:<p>No transactions found.</p>}
          </div>
          <a role="button" className="btn btn-primary mt-4"  href="/main">back</a>
        </div>
      </div>
  );
};

