// EventPayTable.js
// https://blog.logrocket.com/complete-guide-building-smart-data-table-react/

import React, { useEffect, useState, useMemo } from "react";
import { useTable, useFilters, useSortBy, useRowSelect } from "react-table";

let requiredItemList = [];
let outerSelectedFlatRows = [];
let localRequiredItemList = [];

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

/*
    Since "export default" is Child(), EventPayTable won't be imported as EventPayTable in EventPay.js.
    "Child()" is imported as EventPayTable.
*/
//export default function EventPayTable({ columns, data, ParentFunction }) {
function EventPayTable({ columns, data, ParentFunction, ParentSetRequredItemListFunction }) {
    console.log(" @@@@ EventPayTable received data : ",data)
    console.log(" @@@@ EventPayTable received columns : ",columns)
    console.log(" @@@@ EventPayTable received ParentFunction : ",ParentFunction)
    console.log(" @@@@ EventPayTable received ParentSetRequredItemListFunction : ",ParentSetRequredItemListFunction)
    const [filterInput, setFilterInput] = useState("");

    //const [selectedRows, setSelectedRows] = useState([]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
    setFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["data.eventDays"] // https://github.com/TanStack/react-table/issues/1804
      }
    },
    useFilters,
    useSortBy,
    useRowSelect, // this needs to be placed after the "useSortBy" - otherwise, error - will tell you why in that error, though - then why can it automatically process it if it knows what is wrong?? lol
    hooks => {
        // https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/row-selection?file=/src/App.js:1301-2049
      hooks.visibleColumns.push(columns => [
        ...columns,
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div style={{"fontSize": "x-small"}}>Register
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
      ])
    }
  );

  useEffect(() => {
    // Here you will call the Parent Function that was passed from the parent (EventPay.js)
    console.log("ParentFuction being executed in Child (EventPayTable.js)")
    //outerSelectedFlatRows=selectedFlatRows;
    outerSelectedFlatRows=[];
    selectedFlatRows.map((item) => { // building outerSelectedFlatRows off of selectedFlatRows
                                     // this will hold selected items accessible from out side 
      console.log(" >>>>>>>>>>>>>>>>>>>>>>> item: ",item) // item.original - item & itemId
      outerSelectedFlatRows.push(
        {
          item: item.original.item,
          itemId: item.original.itemId
        }
      )
    })
      console.log(" >>>>>>>>>>>>>>>>>>>>>>> outerSelectedFlatRows: ",outerSelectedFlatRows)
    ParentFunction(selectedFlatRows);
  }, [selectedFlatRows]);

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("item", value);
    setFilterInput(value);
  };

  // Render the UI for your table
  return (
    <>
      {
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"Search Event Name"}
      />
      }
      <div style={{"overflow-x":"auto"}}>
      <table {...getTableProps()} id="eventsTable" >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    </>
  );

}

function Child({ data, ParentFunction, ParentSetRequredItemListFunction }) {
  const columns = useMemo(
    () => [
      {
        Header: "Event",
        //accessor: "data.eventFees",
        accessor: "item",
        Cell: ({ value }) => {
          return (
            <>
            {value}
            </>
          );
        },
      },
      {
        Header: "Type",
        //accessor: '', // custom columns  https://www.javaer101.com/en/article/40725321.html
        Cell: (row) => {
          let value = row.row.original.item;
          let dispValue = '';
          if (value.match(/.*foil*/i)) {dispValue='Foil'}
          if (value.match(/.*[eé]p[eé]e*/i)) {dispValue='Épée'}
          if (value.match(/.*saber*/i)) {dispValue='Saber'}
          return <>{dispValue}</>
        },
        accessor: row => { // basically we need proper values in accessor for sorting
          let dispValue = '';
          if (row.item.match(/.*foil*/i)) {dispValue='Foil'}
          if (row.item.match(/.*[eé]p[eé]e*/i)) {dispValue='Épée'}
          if (row.item.match(/.*saber*/i)) {dispValue='Saber'}
          return dispValue
        }
      },
      {
        Header: "Gender",
        //accessor: '', // NG: need empty accessor for sorting to be enabled https://stackoverflow.com/questions/63227603/react-table-sorting-not-working-for-column-with-custom-cell
        accessor: row => {
          return row.gender
        },
        Cell: (row) => {
          let value = row.row.original;
          switch (value.gender) {
            case 'N/A':
              return (<>N/A</>)
            case 'M':
              return (<>Male</>)
            case 'F':
              return (<>Female</>)
            case 'C':
              return (<>CoEd</>)
            default:
              return (<>N/A</>)
          }
        }
      },
      {
        Header: "Start Date/Time",
        accessor: row => {
          return row.startDateTime
        },
        Cell: (row) => {
          let value = row.row.original.startDateTime;
          if (value !== 'NA'){
            let startDate = new Date(value).toLocaleDateString();
            let startTime = new Date(value).toLocaleTimeString([],{hour: '2-digit', minute: '2-digit'}); // removed the "seconds"
            return (
              <>
                {startDate} {startTime}
              </>
            )
          } else return <>N/A</>
        }
      },
      {
        Header: "Fee",
        accessor: row => {
          return row.price; // we don't need the currency
        },
        Cell: (row) => {
          //console.log("  @ @ @ @ @ EventPayTable - Fee - row:", row);
          let value = row.row.original;
          return (
            <>
              {value.currency}&nbsp;{value.price}
            </>
          )
        }
      },
      {
        Header: "Required?",
        accessor: row => {
          return row.required
        },
        Cell: (row) => {
          //
          let value = row.row.original;
          //let newRequiredItemList=null;
          // newUpdatedRequiredList = newUpdatedRequiredList.filter(
          //     (reqItem) => !(reqItem.itemId == item.id)
          // );
          if (value.required && !requiredItemList.some( // also check if exists in the array
              (trakingItem) => (trakingItem.itemId === row.row.original.itemId)
            )) { // if required and does not exist in the array
              console.log(" @@ @@ ------------------ EventPayTables - adding the item to requiredItemList: ", row.row.original.itemId);
            requiredItemList.push(
              // This is to keep track of the items that are
              // required to be paid; however, we won't enforce
              // right now.
              //
              // push only required item
              {
                item: row.row.original.item,
                itemId: row.row.original.itemId
              }
            ); // push
            //newRequiredItemList = requiredItemList;
            ParentSetRequredItemListFunction(requiredItemList);
            localRequiredItemList = requiredItemList;
          }
          console.log("EventPayTable - requiredItemList: ", requiredItemList);
          return (
            <>
              {value.required?<span className="text-danger fw-bold">Yes</span>:"No"}
            </>
          )
        }
      }
    ],
    []
  );

  console.log(" @ > @ > @ > @> EventPayTALBE - log outerSelectedFlatRows: ",outerSelectedFlatRows)
  console.log(" @ > @ > @ > @> EventPayTALBE - log localRequiredItemList: ",localRequiredItemList)
  let newRequiredItemList = [];
  newRequiredItemList = localRequiredItemList;
  outerSelectedFlatRows.map((item,index) => { // driver table is selected item list
    console.log(" @ > @ > @ > @> item of outerSelectedFlatRows: ", item);
    newRequiredItemList = newRequiredItemList.filter((reqItem) => !(reqItem.itemId === item.itemId));
  })
  console.log(" @ > @ > @ > @> FINAL newRequiredItemList: "+JSON.stringify(newRequiredItemList));

  return (
    <>
      <EventPayTable ParentFunction={ParentFunction} ParentSetRequredItemListFunction={ParentSetRequredItemListFunction} columns={columns} data={data} />
      {newRequiredItemList.map((item, index) => {
        return <div className="col text-danger bg-warning mx-4 my-2 py-1">"<span className="fw-bold">{item.item}</span>" is not selected. It is a required item.</div>
      })}
    </>
  );
}

export default Child;
/*
      <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
            },
            null,
            2
          )}
        </code>
        {console.log(" >>>>>>>>>>>>>this is accessible in here >>>> outerSelectedFlatRows:"+JSON.stringify(outerSelectedFlatRows))}
      </pre>
*/

//const columns = useMemo(
//  () => [
//    {
//      Header: "Events",
//      columns: [
//        {
//          Header: "Event",
//          accessor: "item",
//          Cell: ({ value }) => {
//            return (
//              <>
//              {value}
//              </>
//            );
//          },
//        },
//        {
//          Header: "Type",
//          //accessor: '', // custom columns  https://www.javaer101.com/en/article/40725321.html
//          Cell: (row) => {
//            let value = row.row.original.item;
//            let dispValue = '';
//            if (value.match(/.*foil*/i)) {dispValue='Foil'}
//            if (value.match(/.*[eé]p[eé]e*/i)) {dispValue='Épée'}
//            if (value.match(/.*saber*/i)) {dispValue='Saber'}
//            return <>{dispValue}</>
//          },
//          accessor: row => { // basically we need proper values in accessor for sorting
//            let dispValue = '';
//            if (row.item.match(/.*foil*/i)) {dispValue='Foil'}
//            if (row.item.match(/.*[eé]p[eé]e*/i)) {dispValue='Épée'}
//            if (row.item.match(/.*saber*/i)) {dispValue='Saber'}
//            return dispValue
//          }
//        },
//        {
//          Header: "Gender",
//          //accessor: '', // NG: need empty accessor for sorting to be enabled https://stackoverflow.com/questions/63227603/react-table-sorting-not-working-for-column-with-custom-cell
//          accessor: row => {
//            return row.gender
//          },
//          Cell: (row) => {
//            let value = row.row.original;
//            switch (value.gender) {
//              case 'N/A':
//                return (<>N/A</>)
//              case 'M':
//                return (<>Male</>)
//              case 'F':
//                return (<>Female</>)
//              case 'C':
//                return (<>CoEd</>)
//              default:
//                return (<>N/A</>)
//            }
//          }
//        },
//        {
//          Header: "Start Date/Time",
//          accessor: row => {
//            return row.startDateTime
//          },
//          Cell: (row) => {
//            let value = row.row.original.startDateTime;
//            if (value !== 'NA'){
//              let startDate = new Date(value).toLocaleDateString();
//              let startTime = new Date(value).toLocaleTimeString([],{hour: '2-digit', minute: '2-digit'}); // removed the "seconds"
//              return (
//                <>
//                  {startDate} {startTime}
//                </>
//              )
//            } else return <>N/A</>
//          }
//        },
//        {
//          Header: "Fee",
//          accessor: row => {
//            return row.price; // we don't need the currency
//          },
//          Cell: (row) => {
//            //console.log("  @ @ @ @ @ EventPayTable - Fee - row:", row);
//            let value = row.row.original;
//            return (
//              <>
//                {value.currency}&nbsp;{value.price}
//              </>
//            )
//          }
//        },
//        {
//          Header: "Required?",
//          accessor: row => {
//            return row.required
//          },
//          Cell: (row) => {
//            //
//            let value = row.row.original;
//            //let newRequiredItemList=null;
//            // newUpdatedRequiredList = newUpdatedRequiredList.filter(
//            //     (reqItem) => !(reqItem.itemId == item.id)
//            // );
//            if (value.required && !requiredItemList.some( // also check if exists in the array
//                (trakingItem) => (trakingItem.itemId === row.row.original.itemId)
//              )) { // if required and does not exist in the array
//                console.log(" @@ @@ ------------------ EventPayTables - adding the item to requiredItemList: ", row.row.original.itemId);
//              requiredItemList.push(
//                // This is to keep track of the items that are
//                // required to be paid; however, we won't enforce
//                // right now.
//                //
//                // push only required item
//                {
//                  item: row.row.original.item,
//                  itemId: row.row.original.itemId
//                }
//              ); // push
//              //newRequiredItemList = requiredItemList;
//              ParentSetRequredItemListFunction(requiredItemList);
//              localRequiredItemList = requiredItemList;
//            }
//            console.log("EventPayTable - requiredItemList: ", requiredItemList);
//            return (
//              <>
//                {value.required?<span className="text-danger fw-bold">Yes</span>:"No"}
//              </>
//            )
//          }
//        }
//      ],
//    },
//  ],
//  []
//);