import React from "react";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../front-end/src/auth/useUser";

import "./css/EventList.css"

import EventListTable from "./components/EventListTable";

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}

export const EventList = ({ classAct }) => {
  const user = useUser();
  console.log("EventList - user: ", user);
  console.log("EventList - classAct: ", classAct);

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [events, setEvents] = useState([]);
  const [isSkipp, setIsSkipp] = useState(false);

  useEffect(() => {
    const loadEvents = async () => {
      console.log("EventList - useEffect");
      try {
        const response = await axios.get(`https://${baseUrl}/api/events`);
        console.log(" EventList - response: ", response);
        const responseData = response.data.message;
        console.log(" EventList - responseData: ", responseData);
        if (responseData === "No data found.") setIsSkipp(true);
        setEvents(responseData);
        setIsSuccess(true);
        setIsLoading(false);
      } catch (e) {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };

    loadEvents();
  }, [user]);

  const columns = useMemo(
    () => [
      {
        Header: "Events",
        columns: [
          {
            Header: "Event ID",
            accessor: "row.row.original.data.eventId",
            //Cell: ({ value }) => {
            Cell: (row) => {
              let value = row.row.original.data.eventId;
              return (
                <>
                  <Link to={{ pathname: `/eventpayment/${value}` }} >
                    {value}
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Event Start",
            accessor: "data.eventStartDate",
            id: "1",
            //Cell: ({ value }) => {
            Cell: (row) => {
              let eventId = row.row.original.data.eventId;
              let value = row.row.original.data.eventStartDate;
              //let myDate = new Date(value).toLocaleDateString();
              let myDate = new Date(value).toLocaleDateString();
              return (
                <>
                  <Link to={{ pathname: `/eventpayment/${eventId}` }}>
                    {myDate}
                  </Link>
                </>
              );
            },
          },
//          {
//            Header: "Event Duration",
//            accessor: "data.eventDays",
//            show: false,
//            Cell: ({ value }) => {
//              return <>{value}</>;
//            },
//          },
          {
            Header: "Event End",
            id: "3",
            //Cell: (row) => new Date(row.row.original.data.eventStartDate).toLocaleDateString()  // this works
            accessor: (row) => {
              
              let startDate =new Date(row.data.eventStartDate)
              let duration = row.data.eventDays;
              startDate.setDate(startDate.getDate() + duration - 1);
              let myDate = startDate.toLocaleDateString();
              //console.log(" @@ EventList - Event End: ", myDate)
              return {myDate} 
            },
            Cell: (row) => {
              let eventId = row.row.original.data.eventId;
              let startDate = new Date(row.row.original.data.eventStartDate); //toLocaleDateString();
              let duration = row.row.original.data.eventDays;
              startDate.setDate(startDate.getDate() + duration - 1);
              let myDate = startDate.toLocaleDateString();
              return (
                <Link to={{ pathname: `/eventpayment/${eventId}` }} >
                  {myDate}
                </Link>
              );
            },
          },
          {
            Header: "Event Type",
            accessor: "data.eventType",
            //Cell: ({ value }) => {
            Cell: (row) => {
              let value = row.row.original.data.eventType;
              let eventId = row.row.original.data.eventId;
              return (
                <>
                  <Link to={{ pathname: `/eventpayment/${eventId}` }} >
                    {value}
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Title",
            accessor: "data.eventTitle",
            Cell: (row) => {
              let value = row.row.original.data.eventTitle;
              let eventId = row.row.original.data.eventId;
              return (
                <>
                  <Link to={{ pathname: `/eventpayment/${eventId}` }} >
                    {value}
                  </Link>
                </>
              );
            },
          },
        ],
      },
    ],
    []
  );

  if (isLoading) return <p>Loading...</p>;
  if (isSkipp) return <p>No Events found... If you think this is in error, please contact support desk.</p>;
  if (isSuccess) {
    console.log(" EventList - events: ", events);

    return (
      <div className="container lh-sm fw-light px-5 mt-4">
        <h1>Tournaments/Scheduled Activities</h1>
        <div className="row">
          <div className="col-12">
            {console.log(" @@ PaymentActivities - events: ", events)}
            {console.log(" @@ PaymentActivities - events.length: ", events.length)}
            {console.log(" @@ PaymentActivities - events.success: ", events.success)}
            {events ? <EventListTable columns={columns} data={events} /> : <p>No transactions found.</p>}
          </div>
          <a role="button" className="btn btn-primary mt-4" href="/main">
            back
          </a>
        </div>
      </div>
    );
    /*
    // nexted array: https://stackoverflow.com/questions/51615559/react-map-over-nested-array-of-objects
    return (
      <>
        {events.map((event, key) => (
          <>
            <p className={classAct} key={key}>
              &gt;{event._id}
            </p>

            <p>{event.data.eventId}</p>
            <p>{event.data.eventDescriptions}</p>
            <div dangerouslySetInnerHTML={{ __html: purify.sanitize(event.data.eventScheduleDetails) }} />
          </>
        ))}
      </>
    );
    */
  } else {
    return <p className="text-danger bg-warning">...</p>;
  }
};
export default EventList;
/*
    {token.map((event, key) => (
        <Link className="event-list-item" key={key} to={`/event/${event.data.referenceId}`}>
            <h3>{event.data.referenceId}</h3>
            <p>{event.eventUrl[0].substring(0, 150)}...</p>
        </Link>
    ))}
*/