import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import purify from "dompurify";
import { useUser } from "../../../front-end/src/auth/useUser";
import PaidListTable from "./components/PaidListTable";

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}


//export const Event = ({ classAct, event }) => {
export const PaidList = (props) => {
  // export const Event = (props) => {
  console.log(" @ PaidList - props: ", props);
  const { eventId } = useParams();
  console.log(" @ PaidList - eventId: ", eventId);

  const history = useHistory();
  const user = props.userId;

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [events, setEvents] = useState([]);
  const [isSkipp, setIsSkipp] = useState(false);
  const [eventsEventFees, setEventsEventFees] = useState(false);

  // https://react-table.tanstack.com
  // https://stackoverflow.com/questions/51052169/reactjs-tables-how-to-join-multiple-values-into-1-cell
  const columns = useMemo(
    () => [
      {
        Header: "Events",
        columns: [
          {
            Header: "member ID",
            accessor: "info.memberId",
          },
          {
            Header: "Full Name",
            accessor: d => `${d.info.first} ${d.info.middle} ${d.info.last}`,
          },
          {
            Header: "eMail",
            accessor: "email",
          },
          {
            Header: "Phone",
            accessor: "info.phone",
          },
          {
            Header: "AAU#",
            accessor: "info.aauNumber",
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    const loadEvents = async () => {
      console.log("PaidList - useEffect");
      try {
        const response = await axios.get(`https://${baseUrl}/api/paidlist/${eventId}`);
        console.log(" PaidList - response: ", response);
        const responseData = response.data.message;
        console.log(" PaidList - responseData: ", responseData);// winner this time
        //console.log(" PaidList - responseData[0]: ", responseData[0]);
        //console.log(" PaidList - responseData[0].data: ", responseData[0].data);
        if (responseData === "No data found.") setIsSkipp(true);
        setEvents(responseData);
        //setEventsEventFees(responseData[0].data.eventFees);
        setEventsEventFees(responseData);
        setIsSuccess(true);
        setIsLoading(false);
      } catch (e) {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };

    loadEvents();
  }, [user]);

  if (isLoading) return <p>Loading...</p>;
  if (isSkipp) return <p>No prior transactions found</p>;
  if (isSuccess) {
    console.log(" PaidList - events to be processed: ", events);


    // nexted array: https://stackoverflow.com/questions/51615559/react-map-over-nested-array-of-objects
    return (
      <>
        <div className="container">
          <div className="card text-dark">
            {events.map((event, key) => (
              <>
                <div className="card-header">
                </div>
                <div className="card-body">

                <br/>
                  <div className="d-flex justify-content-center d-inline-block">
                    Register &amp; Pay
                  </div>

                  <div className="d-flex justify-content-center pt-5"><button className="btn btn-sm text-light bg-success" onClick={() => history.goBack()}>Back</button></div>

                  <hr/>
                  <h3>Events &amp; Fees</h3>

                  <PaidListTable columns={columns} data={eventsEventFees} />

                </div>
              </>
            ))}
                  <div className="d-flex justify-content-center d-inline-block pb-5">
                    <Link className="btn btn-primary btn-lg ms-5"  to={{ pathname: `/eventpayment/${eventId}`}} >Register &amp; Pay</Link>
                  </div>
          </div>
        <div className="d-flex justify-content-center mt-5"><button className="btn btn-sm text-light bg-success" onClick={() => history.goBack()}>Back</button></div>
        </div>
      </>
    );
  } else {
    return <p className="text-danger bg-warning">...</p>;
  }
};
export default PaidList;