import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useUser } from './useUser';

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

export const CurrentUserLoader = ({ children }) => {
    const [user, setUser] = useState(null); // default value during data loads
    const currentUser = useUser();
    console.log("@@ CurrentUserLoader - currentUser: "+JSON.stringify(currentUser));
    const { id, email, isVerified, info, tobi, } = currentUser; // destructuring assignment

    //const id="61bac51d605c168d4808c7b9";
    const url = `https://${baseUrl}/api/profiles/`+id;
    console.log("   auth/CurrentUserLoader - url: "+url);
    console.log("   auth/CurrentUserLoader - id received: "+id);

    useEffect(() => {
            console.log("   auth/CurrentUserLoader - in useEffect!");
        (async () => {
            const response = await axios.get(url);
            //setUser(response.data["profile"]);
            setUser(response.data);
            console.log("   auth/CurrentUserLoader - response: "+JSON.stringify(response)+"  ----------------");
            console.log("   auth/CurrentUserLoader - response.data: "+JSON.stringify(response.data));
        })();
    },[id]);

    //

    return (
        <>
        {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { user });
            }

            return child;
        })}
        </>
    );
}
