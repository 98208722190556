import axios from 'axios';

let baseUrl='';
if(process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl="mongodbprod.fencingleagueofamerica.org:4443"
} else {
  baseUrl="mongodb01.fencingleagueofamerica.org:4443"
};

export const addPayment = async (requestBody) => {
    try {
    console.log (" @@ addPayment - calling api with requestBody received (JSONified): "+JSON.stringify(requestBody));
    //const response = await axios.put('/api/verify-email', { verificationString });
    const response = await axios.post(`https://${baseUrl}/api/processpayment`, { requestBody });

    console.log (" @@ addPayment - response.data (JSONified): "+JSON.stringify(response.data));
    //console.log (" @@ addPayment - response.data (raw): "+response.data); // raw is JSON
    //return JSON.parse(response.data);
    //return {success: true, message: JSON.parse(response.data)};
    return {success: true, message: response.data};

} catch (error) {
    console.log (" @@ addPayment - ERROR!!!: "+error);
    //return error
    return {success: false, message: error};
}
}